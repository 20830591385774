import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useTable, usePagination, useSortBy, useFilters } from 'react-table/src/index';
import { format, add } from 'date-fns';
import { SelectField, DateRangeSelector } from '../shared/Inputs';
import axios from 'axios';

import { hot } from 'react-hot-loader';

const AnalyticsTable = ({ data, agents, fetchData, totalPages }) => {
  const columns = useMemo(() => {
    return [
      // HIDDEN COLUMNS
      {
        Header: '__id',
        accessor: 'id',
      },
      {
        Header: '__agent_id',
        accessor: 'agent_id',
      },
      {
        Header: '__agent_role',
        accessor: 'agent_role',
      },
      {
        Header: '__event_type',
        accessor: 'event_type',
      },
      // VISIBLE COLUMNS
      {
        Header: 'Date',
        accessor: 'time',
        width: '25%',
        sortDescFirst: true,
        Cell: ({ cell: { value }}) => {
          const date = new Date(value);
          return (
            <span>{format(date, "MMMM d, yyyy h:mma")}</span>
          );
        },
      },
      {
        Header: 'Agent',
        accessor: 'agent_name',
        width: '25%',
        Cell: ({ data, row, cell: { value } , ...props}) => {
          const obj = data[row.index];
          return (
            <a href={`/users/${obj.agent_id}`}>{value}</a>
          );
        },
      },
      {
        Header: 'Action',
        accessor: 'event',
        width: '50%',
        disableSortBy: true,
      },
    ];
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // Filtering
    setFilter,
    // Pagination
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, filters, sortBy },
  } = useTable({
    columns,
    data,
    initialState: {
      hiddenColumns: ['id', 'agent_id', 'agent_role', 'event_type'],
      pageIndex: 0,
      pageSize: 25,
      sortBy: [{ id: 'time', desc: true }],
    },
    // Pagination
    manualPagination: true,
    pageCount: totalPages,
    // Filtering
    manualFilters: true,
    // Sorting
    manualSortBy: true,
    disableMultiSort: true,
    disableSortRemove: true,
  }, useFilters, useSortBy, usePagination);

  const [agentRoleFilter, setAgentRoleFilter] = useState({ label: 'Any', value: 'any' });
  const [agentFilter, setAgentFilter] = useState([]);
  const [eventFilter, setEventFilter] = useState([]);
  const [dateRange, setDateRange] = useState({});

  useEffect(() => {
    fetchData({ pageIndex, pageSize, filters, sortBy, dateRange });
  }, [fetchData, pageIndex, pageSize, filters, sortBy, dateRange]);

  const agentRoleOptions = useMemo(() => [
    { label: 'Any', value: 'any' },
    { label: 'Elite', value: 'agent' },
    { label: 'Non-Elite', value: 'non_elite' },
  ], [])

  const agentOptions = useMemo(() => {
    return agents
      .filter(agent => agentRoleFilter.value === 'any' || agentRoleFilter.value === agent.role)
      .map(agent => ({ label: agent.name, value: agent.id }));
  }, [agentRoleFilter]);

  const eventTypeOptions = useMemo(() => {
    return [
      { label: 'Login', value: 'login' },
      { label: 'Setup Password', value: 'setup_password' },
      { label: 'Setup 2FA', value: 'setup_otp' },
      { label: 'Access Project', value: 'access_project' },
      { label: 'Access Project Resource', value: 'access_resource' },
      { label: 'Download Project Resource Folder', value: 'download_folder' },
      { label: 'Read Message', value: 'read_message' },
      { label: 'Event RSVP', value: 'rsvp' },
      { label: 'Inactive', value: 'inactive_14' },
    ];
  }, []);

  const renderPagination = () => {
    return (
      <div className="table-nav">
        <div><a className={canPreviousPage && 'enabled' || 'disabled'} onClick={() => gotoPage(0)}>First</a></div>
        <div><a className={canPreviousPage && 'enabled' || 'disabled'} onClick={() => previousPage()}>Previous</a></div>
        <div className="page-info">Page {pageIndex + 1} of {pageCount}</div>
        <div><a className={canNextPage && 'enabled' || 'disabled'} onClick={() => nextPage()}>Next</a></div>
        <div><a className={canNextPage && 'enabled' || 'disabled'} onClick={() => gotoPage(pageCount - 1)}>Last</a></div>
      </div>
    );
  };

  const renderSortIcon = (column) => {
    if (column.isSorted) {
      const icon = column.isSortedDesc ? 'keyboard_arrow_down' : 'keyboard_arrow_up';
      return <i className="material-icons">{icon}</i>;
    } else {
      return null;
    }
  };
  const handleSetAgentRoleFilter = (role) => {
    setAgentRoleFilter(role);
    setAgentFilter([]);
    setFilter('agent_id', []);
    setFilter('agent_role', role.value);
  };

  const handleSetAgentFilter = (agents) => {
    setAgentFilter(agents || []);
    setFilter('agent_id', (agents || []).map(agent => agent.value));
  };

  const handleSetEventFilter = (events) => {
    setEventFilter(events);
    setFilter('event_type', (events || []).map(event => event.value));
  };

  return (
    <>
      <DateRangeSelector onChange={setDateRange}/>
      <div className="pure-g">
        <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-3 pure-u-xl-1-4 form-padded">
          <SelectField label="Agent Type" options={agentRoleOptions} value={agentRoleFilter} onChange={handleSetAgentRoleFilter}/>
        </div>
        <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-3 pure-u-xl-1-4 form-padded">
          <SelectField label="Agent" options={agentOptions} value={agentFilter} onChange={handleSetAgentFilter}
            isClearable={true} isMulti={true}/>
        </div>
        <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-3 pure-u-xl-1-4 form-padded">
          <SelectField label="Action" options={eventTypeOptions} value={eventFilter} onChange={handleSetEventFilter}
            isClearable={true} isMulti={true}/>
        </div>
      </div>
      <br/>
      <table className="pure-table full-width" {...getTableProps()}>
        <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps({ style: { width: column.width } }))}>
                {column.render('Header')}
                {renderSortIcon(column)}
              </th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
        {!page.length && (
          <tr>
            <td colSpan={1000}>No Results</td>
          </tr>
        )}
        </tbody>
      </table>
      {renderPagination()}
    </>
  );
};

const AnalyticsPage = ({ agents }) => {
  const [ data, setData ] = useState([]);
  const [ totalPages, setTotalPages ] = useState(0);

  const fetchData = useCallback(({ filters, pageIndex, pageSize, sortBy, dateRange }) => {
    if (!dateRange.startDate) return;
    const agentRole = filters.find((f) => f.id === 'agent_role')?.value;
    const agentId = filters.find((f) => f.id === 'agent_id')?.value;
    const eventName = filters.find((f) => f.id === 'event_type')?.value;
    const { id: sortColumn, desc: sortDesc } = (sortBy && sortBy[0]) || {};
    axios.get('/users/events.json', {
      params: {
        agent_role: agentRole,
        agent_id: agentId,
        event_name: eventName,
        page: pageIndex,
        page_size: pageSize,
        sort_column: sortColumn,
        sort_desc: sortDesc,
        start_date: format(dateRange.startDate, 'yyyy-MM-dd'),
        end_date: format(add(dateRange.endDate, { days: 2 }), 'yyyy-MM-dd'),
      }
    }).then((response) => {
      setData(response.data.events);
      setTotalPages(Math.ceil(response.data.total / pageSize));
    })
  }, []);

  return (
    <AnalyticsTable data={data} totalPages={totalPages} agents={agents} fetchData={fetchData}/>
  );
};

export default hot(module)(AnalyticsPage);
