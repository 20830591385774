import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import axios from 'axios';
import { hot } from 'react-hot-loader';
import Turbolinks from 'turbolinks';

import { FormTextField, FormSelectField, FormDatePicker, RichTextField } from "../shared/Inputs";

let Form = function({ event, projects, email }) {
  const form = useForm({ defaultValues: event });
  const { register, handleSubmit, errors, getValues, setValue } = form;

  const [isSaving, setSaving] = useState(false);
  const [serverError, setServerError] = useState(null);

  const [isLoadingSheets, setIsLoadingSheets] = useState(true);
  const [gsheets, setGsheets] = useState([]);

  useEffect(() => {
    if (gsheets.length === 0 && !isLoadingSheets) return;

    axios.get('/events/sheets')
      .then(response => {
        setIsLoadingSheets(false);
        setGsheets(response.data);
      })
      .catch(e => {
        setServerError(e.response?.data?.error ?? 'There was a problem loading the Google Sheets, please try again later.');
      })
  }, []);

  const onSubmit = data => {
    setSaving(true);
    setServerError(null);

    let result = event.id ? axios.patch(`/events/${event.id}.json`, { event: data }) : axios.post('/events.json', { event: data });
    result.then(response => {
      Turbolinks.visit(`/events/${response.data.id}`);
    })
      .catch(e => {
        setServerError(e.response?.data?.error ?? 'There was a problem saving the event, please try again later.');
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const projectOptions = projects.map(project => ({ label: project.name, value: project.id }));
  const gsheetOptions = gsheets.sort((a, b) => a < b ? -1 : 1).map(gsheet => ({ label: gsheet.title, value: gsheet.id }));

  let registerEndDate = () => {
    return register({
      required: 'This field is required',
      validate: (value) => {
        if (!value) return true;
        const start = getValues().start_date;
        if (!start) return true;
        const startDate = new Date(start);
        const endDate = new Date(value);
        return startDate < endDate || 'must be after start date';
      }
    })
  };

  return (
    <div className="page-content">
      {serverError && <div className='error_notification'>{serverError}</div>}
      <form id="event-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-inputs pure-g">
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormTextField label="Name" name="name" type="text" reg={register({required: 'This field is required'})} errors={errors}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormTextField label="Location" name="location" type="text" reg={register({required: 'This field is required'})} errors={errors}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormDatePicker label="Start Date" name="start_date" selectTime={true} selectsStart={true} endName={'end_date'}
              form={form} register={register({required: 'This field is required'})} errors={errors}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormDatePicker label="End Date" name="end_date" selectTime={true} selectsEnd={true} startName={'start_date'}
              form={form} register={registerEndDate()} errors={errors}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormSelectField label="Project" name="project_id" options={projectOptions} form={form} register={register}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormSelectField label="Google Sheet" name="gsheet_id" options={gsheetOptions} isLoading={isLoadingSheets} form={form} register={register}/>
          </div>
        </div>
        <div className="pure-g">
          <div className="pure-u-1">
            <br/>
            Share a Google Sheet with <a href={`mailto:${email}`}>{email}</a> to add it to the list. Sheets used for other events will not be shown.
          </div>
        </div>
        <div className="pure-g">
          <div className="pure-u-1">
            <br/>
            <div style={{textTransform: 'uppercase'}}>Notes</div>
          </div>
          <div className="message-wrapper pure-u-1">
            <RichTextField name="notes" form={form}/>
          </div>
        </div>
        <div className="pure-g">
          <div className="pure-u-1 pure-u-lg-1-2">
            <br/>
            <input type="submit" disabled={isSaving} value={isSaving ? "Saving..." : event.id ? 'Save' : 'Create'}/>
          </div>
        </div>
      </form>
    </div>
  );
};

export default hot(module)(Form);
