import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import axios from 'axios';
import { hot } from 'react-hot-loader';
import Turbolinks from 'turbolinks';

import { FormTextField, FormSelectField, FormDatePicker } from "../shared/Inputs";

let Form = function({ project, is_blackline_configured }) {
  const form = useForm({ defaultValues: project });
  const { register, handleSubmit, errors, watch, getValues } = form;

  const [isSaving, setSaving] = useState(false);
  const [serverError, setServerError] = useState(null);

  const [isLoadingBlacklineProjects, setIsLoadingBlacklineProjects] = useState(is_blackline_configured);
  const [blacklineProjects, setBlacklineProjects] = useState([]);

  useEffect(() => {
    if (blacklineProjects.length === 0 && !isLoadingBlacklineProjects) return;

    axios.get('/projects/blackline_projects')
      .then(response => {
        setIsLoadingBlacklineProjects(false);
        setBlacklineProjects(response.data.map((name) => ({label: name, value: name})));
      })
      .catch(e => {
        setServerError(e.response?.data?.error ?? 'There was a problem loading the Blackline project list, please try again later.');
      })
  }, []);

  const blacklineProject = watch('blackline_project_name');

  const onSubmit = data => {
    setSaving(true);
    setServerError(null);

    let result = project.id ? axios.patch(`/projects/${project.id}.json`, { project: data }) : axios.post('/projects.json', { project: data });
    result.then(response => {
        Turbolinks.visit(`/projects/${response.data.id}`);
      })
      .catch(e => {
        setServerError(e.response?.data?.error ?? 'There was a problem saving the project, please try again later.');
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const activeOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  const visibleOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  let registerEndDate = () => {
    return register({
      validate: (value) => {
        if (!value) return true;
        const start = getValues().powerboost_start_date;
        if (!start) return true;
        const startDate = new Date(start);
        const endDate = new Date(value);
        return startDate < endDate || 'must be after start date';
      }
    })
  };

  return (
    <div className="page-content">
      {serverError && <div className='error_notification'>{serverError}</div>}
      <form id="project-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-inputs pure-g">
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormTextField label="Name" name="name" type="text" reg={register({ required: 'This field is required' })}
              errors={errors}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormTextField label="Email" name="email" type="email"
              reg={register({ required: 'This field is required' })} errors={errors}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormTextField label="Phone Number" name="phone_number" type="tel" placeholder="(123) 456-7890"
              reg={register} errors={errors}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormTextField label="Website" name="website_url" type="url"
              reg={register({ required: 'This field is required' })} errors={errors}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormTextField label="Worksheet Submission URL" name="worksheet_url" type="url" reg={register}
              errors={errors}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormSelectField label="Active" name="is_active" options={activeOptions} form={form}
              reg={register({ required: 'This field is required' })}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormSelectField label="Blackline Project" name="blackline_project_name" options={blacklineProjects}
              isClearable={true} isDisabled={!is_blackline_configured} isLoading={isLoadingBlacklineProjects}
              form={form} reg={register}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormSelectField label="Blackline Sync Enabled" name="blackline_sync_enabled" options={activeOptions}
              isDisabled={!blacklineProject || !is_blackline_configured} form={form}
              reg={register({ required: 'This field is required' })}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormSelectField label="Blackline Sync Dry Run" name="blackline_dry_run" options={activeOptions}
              isDisabled={!blacklineProject || !is_blackline_configured} form={form}
              reg={register({ required: 'This field is required' })}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormDatePicker label="Blackline Sync Start Date" name="blackline_sync_start"
              isDisabled={!blacklineProject || !is_blackline_configured} placeHolder='Leave blank to sync all deals'
              form={form} register={register}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormDatePicker label="Powerboost Start Date" name="powerboost_start_date" selectsStart={true}
              endName={'powerboost_end_date'} autoEndDuration={null}
              form={form} register={register} errors={errors} placeHolder="Leave blank for all deals"/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormDatePicker label="Powerboost End Date" name="powerboost_end_date" selectsEnd={true}
              startName={'powerboost_start_date'}
              form={form} register={registerEndDate()} errors={errors} placeHolder="Leave blank for all deals"/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormTextField label="Powerboost Multiplier" name="powerboost_multiplier" type="number" step="0.01" min="0"
              reg={register({ required: 'This field is required' })} errors={errors}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormTextField label="Promo Banner" name="promo" type="text" reg={register} errors={errors}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormSelectField label="Visible on Administrator Portal" name="is_visible" options={visibleOptions}
              form={form} reg={register({ required: 'This field is required' })}/>
          </div>
        </div>
        <div className="pure-g">
          <div className="pure-u-1 pure-u-lg-1-2">
            <input type="submit" disabled={isSaving} value={isSaving ? "Saving..." : project.id ? 'Save' : 'Create'}/>
          </div>
        </div>
      </form>
    </div>
  );
};

export default hot(module)(Form);
