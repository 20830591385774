import React, { useState, useEffect, useMemo, useRef, Fragment } from 'react';
import { add, format } from 'date-fns';
import axios from 'axios';
import { hot } from 'react-hot-loader';

function useHover() {
  const [value, setValue] = useState(false);

  const ref = useRef(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);

      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, [ref.current]);

  return [ref, value];
}

const Event = ({ event, rsvp }) => {
  const gcalLink = useMemo(() => {
    let startDate = new Date(event.start_date);
    startDate = add(startDate, { minutes: startDate.getTimezoneOffset() });
    let endDate = new Date(event.end_date);
    endDate = add(endDate, { minutes: endDate.getTimezoneOffset() });
    const start = format(startDate, "yyyyMMdd'T'HHmmss'Z'");
    const end = format(endDate, "yyyyMMdd'T'HHmmss'Z'");
    return `https://calendar.google.com/calendar/r/eventedit?text=${encodeURIComponent(event.name)}&dates=${start}/${end}&details=&location=${encodeURIComponent(event.location)}`;
  }, [ event ]);

  const owaLink = useMemo(() => {
    let startDate = new Date(event.start_date);
    startDate = add(startDate, { minutes: startDate.getTimezoneOffset() });
    let endDate = new Date(event.end_date);
    endDate = add(endDate, { minutes: endDate.getTimezoneOffset() });
    const start = format(startDate, "yyyy-MM-dd'T'HH:mm:ss");
    const end = format(endDate, "yyyy-MM-dd'T'HH:mm:ss");
    return `https://outlook.office.com/owa/?path=/calendar/action/compose&subject=${encodeURIComponent(event.name)}&location=${encodeURIComponent(event.location)}&startdt=${start}&enddt=${end}&body=`
  }, [ event ]);

  const [hoverRef, isHovered] = useHover();

  const renderCalendarDropdown = () => {
    return (
      <Fragment>
        <br/>
        <div className="dropdown">
          <span><a>Add to Calendar</a></span>
          <div className="dropdown-content">
            <a href={gcalLink} target="_blank">Google Calendar</a>
            <a href={owaLink} target="_blank">Office365 Outlook</a>
            <a href={`/events/${event.id}.ics`} target="_blank">Other (.ics)</a>
          </div>
        </div>
      </Fragment>
    );
  };

  const linkStyle = event.is_attending && isHovered ? { color: '#FF3E3E' } : {};

  return (
    <div className="sidebar-event">
      <div className="name">{event.name}</div>
      <div className="pure-g">
        <div className="location pure-u-1-2">{event.location}</div>
        <div className="date pure-u-1-2">
          {format(new Date(event.start_date), "MMM d, yyyy h:mma")}
          <br/>
          <a ref={hoverRef} style={linkStyle} onClick={() => rsvp(event)}>
            {event.is_attending && (isHovered && 'No longer attending' || 'Attending') || 'Click here to attend'}
          </a>
          {event.is_attending && renderCalendarDropdown()}
        </div>
      </div>
    </div>
  );
};

const Events = ({ initial_events }) => {
  const [ events, setEvents ] = useState(initial_events);
  const [ error, setError ] = useState(null);

  const rsvp = (event) => {
    axios.post(`/events/${event.id}/rsvp`)
      .then(() => {
        setEvents(events.map(e => e.id === event.id ? {...e, is_attending: !e.is_attending} : e))
      })
      .catch(e => {
        setError(e.response?.data?.error ?? 'There was a problem saving your RSVP, please try again later.');
      });
  };

  const renderEvents = () => {
    if (events.length === 0) {
      return (<div>No upcoming events</div>);
    }

    return events.map(event => <Event key={event.id} event={event} rsvp={rsvp}/>);
  };

  return (
    <div className="event-list">
      {error && <div className='error_notification'>{error}</div>}
      {renderEvents()}
      <br/>
      <div>Want to host an event for your brokerage or your clients? <a href="mailto:info@radmarketing.ca">Contact us!</a></div>
    </div>
  );
};

export default hot(module)(Events);
