import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter } from 'react-table/src/index';
import { add, format } from 'date-fns';
import { CSVDownload } from "react-csv";
import { SelectField, DateRangeSelector } from '../shared/Inputs';
import axios from 'axios';

import { hot } from 'react-hot-loader';

const oneOfFilter = (rows, ids, filterValue) => {
  return rows.filter((row) => {
    return ids.some((id) => {
      const rowValue = row.values[id];
      return !filterValue || filterValue.length === 0 || filterValue.includes(rowValue);
    });
  });
};

const numberFormat = new Intl.NumberFormat('en-US', {});

const DownloadButton = ({ rows, prepareRow }) => {
  const [shouldDownload, setShouldDownload] = useState(false);
  const data = useMemo(() => {
    if (!shouldDownload) return [];
    return rows.map(row => {
      prepareRow(row);
      const groupBy = row.cells[0]?.column?.isGrouped && ['yes'] || [];
      let obj = {};
      row.cells.forEach(cell => {
        if (cell.column.id !== 'actions') {
          obj[cell.column.Header] =
            cell.column.csvValue && cell.column.csvValue(cell.value, groupBy.length && !cell.column.isGrouped) || cell.value;
        }
      });
      return obj;
    });
  }, [rows, shouldDownload]);

  useEffect(() => {
    setTimeout(() => {
      setShouldDownload(false);
    }, 1);
  }, [shouldDownload]);

  return (
    <>
      <a className="button" onClick={() => setShouldDownload(true)}>Export CSV</a>
      {shouldDownload && data.length && <CSVDownload data={data}/>}
    </>
  );
};

const ProjectsTable = ({
  data,
  setIsActive,
}) => {
  const columns = useMemo(() => {
    return [
      // HIDDEN COLUMNS
      {
        Header: '__project_id',
        accessor: 'id',
        filter: oneOfFilter,
        disableGlobalFilter: true,
      },
      // VISIBLE COLUMNS
      {
        Header: 'Name',
        accessor: 'name',
        width: '5%',
      },
      {
        Header: 'Email',
        accessor: 'email',
        width: '10%',
        Cell: ({ cell: { value }}) => {
          return (<a href={`mailto:${value}`}>{value}</a>);
        },
      },
      {
        Header: 'Phone Number',
        accessor: 'phone_formatted',
        width: '10%',
        Cell: ({ data, row, cell: { value }}) => {
          if (!value) return '-';
          const obj = data[row.index];
          return (<a href={`tel:${obj.phone}`}>{value}</a>);
        }
      },
      {
        Header: 'Avg Sale Price',
        accessor: 'sales_average',
        width: '10%',
        Cell: ({ cell: { value }}) => {
          return value && `$${numberFormat.format(Math.round(value / 100))}` || '-';
        },
        csvValue: (value) => numberFormat.format(Math.round(value / 100)),
        disableGlobalFilter: true,
      },
      {
        Header: 'Firm Sales',
        accessor: 'sales_completed',
        width: '10%',
        Cell: ({ cell: { value }}) => {
          return value && `$${numberFormat.format(Math.round(value / 100))}` || '-';
        },
        csvValue: (value) => numberFormat.format(Math.round(value / 100)),
        filter: 'between',
        disableGlobalFilter: true,
      },
      {
        Header: 'Pending Sales',
        accessor: 'sales_pending',
        width: '10%',
        Cell: ({ cell: { value }}) => {
          return value && `$${numberFormat.format(Math.round(value / 100))}` || '-';
        },
        csvValue: (value) => numberFormat.format(Math.round(value / 100)),
        filter: 'between',
        disableGlobalFilter: true,
      },
      {
        Header: 'Rescinded Sales',
        accessor: 'sales_rescinded',
        width: '10%',
        Cell: ({ cell: { value }}) => {
          return value && `$${numberFormat.format(Math.round(value / 100))}` || '-';
        },
        csvValue: (value) => numberFormat.format(Math.round(value / 100)),
        filter: 'between',
        disableGlobalFilter: true,
      },
      {
        Header: 'Active',
        accessor: 'is_active',
        width: '5%',
        Cell: ({ cell: { value }}) => {
          return value && '✔' || '✘';
        },
        csvValue: (value) => value && 'true' || 'false',
        filter: 'exact',
        disableGlobalFilter: true,
      },
      {
        Header: 'Actions',
        id: 'actions',
        width: '10%',
        Cell: ({ data, row }) => {
          const obj = data[row.index];
          return (
            <span className='table-actions'>
              <a href={`/projects/${obj.id}`}>View</a>
              <a href={`/projects/${obj.id}/edit`}>Edit</a>
              {window.isSuperAdmin && <a href={`/projects/${obj.id}`} data-method="DELETE" data-confirm="Are you sure?">Delete</a>}
            </span>
          );
        },
      }
    ];
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    toggleHideColumn,
    rows,
    // Filtering
    setGlobalFilter,
    setFilter,
    preFilteredRows,
    // Pagination
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex },
  } = useTable({
    columns,
    data,
    initialState: {
      hiddenColumns: ['id'],
      pageIndex: 0,
      pageSize: 25,
    },
    // Sorting
    disableMultiSort: true,
    disableSortRemove: true,
  }, useFilters, useGlobalFilter, useSortBy, usePagination);

  const [searchFilter, setSearchFilter] = useState(null);
  const [activeFilter, setActiveFilter] = useState({ label: 'Yes', value: true });

  const booleanOptions = useMemo(() => {
    return [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ];
  }, []);

  const renderPagination = () => {
    return (
      <div className="table-nav">
        <div><a className={canPreviousPage && 'enabled' || 'disabled'} onClick={() => gotoPage(0)}>First</a></div>
        <div><a className={canPreviousPage && 'enabled' || 'disabled'} onClick={() => previousPage()}>Previous</a></div>
        <div className="page-info">Page {pageIndex + 1} of {pageCount}</div>
        <div><a className={canNextPage && 'enabled' || 'disabled'} onClick={() => nextPage()}>Next</a></div>
        <div><a className={canNextPage && 'enabled' || 'disabled'} onClick={() => gotoPage(pageCount - 1)}>Last</a></div>
      </div>
    );
  };

  const renderSortIcon = (column) => {
    if (column.isSorted) {
      const icon = column.isSortedDesc ? 'keyboard_arrow_down' : 'keyboard_arrow_up';
      return <i className="material-icons">{icon}</i>;
    } else {
      return null;
    }
  };

  const handleSetSearchFilter = (e) => {
    const search = e.currentTarget.value;
    setSearchFilter(search);
    setGlobalFilter(search);
  };

  const handleSetActiveFilter = (isActive) => {
    setActiveFilter(isActive);
    setIsActive(isActive?.value);
  };

  return (
    <>
      <div className="pure-g">
        <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-2 pure-u-xl-1-4">
          <div className="input pure-u-23-24">
            <label>Search</label>
            <input type="text" value={searchFilter || ''} onChange={handleSetSearchFilter}/>
          </div>
        </div>
        <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-2 pure-u-xl-1-4">
          <SelectField className="pure-u-md-23-24" label="Active" options={booleanOptions} value={activeFilter} onChange={handleSetActiveFilter}/>
        </div>
      </div>
      <br/>
      <table className="pure-table full-width" {...getTableProps()}>
        <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps({ style: { width: null } }))}>
                <div className="header">
                  <div>{column.render('Header')}</div>
                  <div className="sort-icon">
                    {renderSortIcon(column)}
                  </div>
                </div>
              </th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          )
        })}
        {!rows.length && (
          <tr>
            <td colSpan={1000}>No Results</td>
          </tr>
        )}
        </tbody>
      </table>
      {renderPagination()}
      <br/>
      <div className="pure-g">
        <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-2 pure-u-xl-1-4">
          <DownloadButton rows={rows} prepareRow={prepareRow}/>
        </div>
      </div>
    </>
  );
};


const ProjectsPage = ({ }) => {
  const [ data, setData ] = useState([]);
  const [ dateRange, setDateRange ] = useState(null);
  const [ isActive, setIsActive ] = useState(true);

  const fetchData = useCallback(({ dateRange, isActive }) => {
    axios.get('/projects.json', {
      params: {
        start_date: format(dateRange.startDate, 'yyyy-MM-dd'),
        end_date: format(dateRange.endDate, 'yyyy-MM-dd'),
        is_active: isActive,
      }
    }).then((response) => {
      setData(response.data.projects);
    })
  }, []);

  useEffect(() => {
    if (!dateRange) return;
    fetchData({ dateRange, isActive });
  }, [dateRange, isActive]);

  return (
    <>
      <DateRangeSelector onChange={setDateRange} defaultType={{ label: 'Year-to-date', value: 'ytd' }}/>
      <ProjectsTable data={data} fetchData={fetchData} setIsActive={setIsActive}/>
    </>
  );
};

export default hot(module)(ProjectsPage);
