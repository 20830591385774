import React, { useState, useEffect, useMemo } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import Cookies from 'js-cookie';
import { hot } from 'react-hot-loader';

const MessagesModal = ({ messages, selectedMessage, setSelectedMessage, isOpen, onClose, onUpdateMessage }) => {
  if (!isOpen) return null;

  const markRead = () => {
    if (selectedMessage.is_read) return;
    axios.post(`/messages/${selectedMessage.id}/read`)
      .then(() => {
        onUpdateMessage({ ...selectedMessage, is_read: true });
      });
  };

  const previousMessage = () => {
    markRead();
    const selectedIndex = messages.findIndex(m => m.id === selectedMessage.id);
    setSelectedMessage(messages[selectedIndex - 1]);
  };

  const nextMessage = () => {
    markRead();
    const selectedIndex = messages.findIndex(m => m.id === selectedMessage.id);
    if (selectedIndex === messages.length - 1) {
      onClose()
    } else {
      setSelectedMessage(messages[selectedIndex + 1]);
    }
  };

  const close = () => {
    if (selectedMessage && !selectedMessage.is_read) {
      Cookies.set('snooze_messages', new Date().toISOString(), { expires: 1 });
    }
    onClose();
  };

  const renderMessageContent = () => {
    if (!selectedMessage) return;

    return (
      <React.Fragment>
        <h1 className='message-subject'>{selectedMessage.subject}</h1>
        <div dangerouslySetInnerHTML={{ __html: selectedMessage.content }}/>
      </React.Fragment>
    );
  };

  const renderPrevious = () => {
    if (!selectedMessage) return null;
    const selectedIndex = messages.findIndex(m => m.id === selectedMessage.id);
    if (selectedIndex <= 0) return null;

    return (
      <a className='button' onClick={previousMessage}><i className='material-icons'>chevron_left</i> Previous</a>
    );
  };

  const renderReadLater = () => {
    if (!selectedMessage) return null;
    const selectedIndex = messages.findIndex(m => m.id === selectedMessage.id);
    if (selectedMessage.is_read && selectedIndex >= messages.length - 1) return null;
    const text = selectedMessage.is_read ? 'Close' : 'Read Later';

    return (
      <a onClick={close} className='button' style={{marginRight: '8px'}}>{text} <i className='material-icons'>close</i></a>
    );
  };

  const renderNext = () => {
    if (!selectedMessage) return null;
    const selectedIndex = messages.findIndex(m => m.id === selectedMessage.id);
    const [text, icon] = (selectedIndex < messages.length - 1) ? ['Next', 'chevron_right'] : ['Close', 'close']

    return (
      <a className='button' onClick={nextMessage}>{text} <i className='material-icons'>{icon}</i></a>
    );
  };

  return (
    <Modal isOpen={isOpen} contentLabel='Messages' className='messages-modal'>
      <div className='top-nav'>
        {renderPrevious()}
        <div className='spacer'/>
        {renderReadLater()}
        {renderNext()}
      </div>
      <div className='content-wrapper'>
        {renderMessageContent()}
      </div>
    </Modal>
  );
};

const Messages = ({ initial_messages }) => {
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [showUnreadOnly, setShowUnreadOnly] = useState(false);
  const [messages, setMessages] = useState(initial_messages);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!isInitialLoad) return;

    const snooze = Cookies.get('snooze_messages');
    if (messages.length > 0 && (!snooze || snooze < messages[0].created_at)) {
      const firstUnread = messages.find(m => !m.is_read);
      if (firstUnread) {
        setShowUnreadOnly(true);
        setSelectedMessage(firstUnread);
        setIsModalOpen(true);
      }
    }

    setIsInitialLoad(false);
  }, [isInitialLoad]);

  let filteredMessages = useMemo(() => {
    return showUnreadOnly ? messages.filter(m => !m.is_read) : messages;
  }, [messages, showUnreadOnly]);

  const openMessage = (message) => {
    setShowUnreadOnly(false);
    setSelectedMessage(message);
    setIsModalOpen(true);
  };

  const closeMessages = () => {
    setShowUnreadOnly(false);
    setIsModalOpen(false);
  };

  const updateMessage = (message) => {
    setMessages(messages.map(m => m.id === message.id ? message : m));
  };

  const renderMessageIcon = (isRead) => {
    if (isRead) {
      return (
        <svg style={{width: '24px', height: '24px'}} viewBox="0 0 24 24">
          <path fill="currentColor" d="M21.03 6.29L12 .64L2.97 6.29C2.39 6.64 2 7.27 2 8V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 7.27 21.61 6.64 21.03 6.29M20 18H4V10L12 15L20 10V18M12 13L4 8L12 3L20 8L12 13Z" />
        </svg>
      );
    } else {
      return (<i className="material-icons">mail</i> );
    }
  };

  const renderMessages = () => {
    if (messages.length === 0) {
      return (<div>No recent messages</div>);
    }

    return messages.map(message => {
      return (
        <div key={message.id} className="sidebar-message" onClick={() => openMessage(message)}>
          <div className="icon-wrapper">{renderMessageIcon(message.is_read)}</div>
          <div className="subject">{message.subject}</div>
        </div>
      );
    });
  };

  return (
    <div className="message-list">
      {renderMessages()}
      <MessagesModal messages={filteredMessages} selectedMessage={selectedMessage} setSelectedMessage={setSelectedMessage}
        onUpdateMessage={updateMessage} isOpen={isModalOpen} onClose={closeMessages}/>
    </div>
  );
};

export default hot(module)(Messages);
