// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'purecss/build/pure.css';
import 'purecss/build/grids-responsive.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'trix/dist/trix.css';
import 'tippy.js/dist/tippy.css';
import 'stylesheets/application.scss';
import 'api';

import RailsUJS from '@rails/ujs';
import Turbolinks from 'turbolinks';
import ReactRailsUJS from 'react_ujs';
import LocalTime from 'local-time';
import 'trix';
import '@rails/actiontext';
import tippy, { createSingleton } from 'tippy.js';

import 'alpine-turbo-drive-adapter';
import 'alpinejs';

const adminMetaTag = document.querySelector('meta[name="is_super_admin"]');
window.isSuperAdmin = adminMetaTag && adminMetaTag.content === 'true';

RailsUJS.start();
Turbolinks.start();
LocalTime.start();
require('@rails/activestorage').start();

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('images', true);
const imagePath = (name) => images(name, true);

// Support component names relative to this directory:
ReactRailsUJS.useContext(require.context('components', true));
ReactRailsUJS.detectEvents();

let tips, tippySingleton;
window.refreshTips = () => {
  if (tippySingleton) {
    tippySingleton.destroy();
  }

  if (tips) {
    tips.forEach((tip) => tip.destroy());
  }

  tips = tippy('[data-tippy-content]');
  tippySingleton = createSingleton(tips);
}

document.addEventListener('turbolinks:load', () => {
  window.refreshTips();
});

Alpine.addMagicProperty('clipboard', function() {
  return data => {
    return window.navigator.clipboard.writeText(data);
  };
})
