import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios';
import QRCode from 'qrcode.react';
import { hot } from 'react-hot-loader';

import { SelectField } from "../shared/Inputs";

let EmailMethod = function({ user, didSendCode, setDidSendCode }) {
  let [isSendingCode, setIsSendingCode] = useState(false);
  let [error, setError] = useState(null);

  let sendCode = useCallback(() => {
    setIsSendingCode(true);
    setError(null);

    axios.post('/setup/otp/send', { method: 'email' })
      .then(() => {
        setDidSendCode(true);
        setIsSendingCode(false);
      })
      .catch(e => {
        setIsSendingCode(false);
        setError(e.response?.data?.error ?? 'Could not send verification message, please try again later.')
      })
  }, []);

  let renderSendCode = function() {
    if (didSendCode) return;

    let sendButtonText = isSendingCode ? 'Sending...' : 'Send me the code';
    return (
      <React.Fragment>
        <p>You will be emailed at {user.email} with a 6 digit code.</p>
        <button className="full-width" disabled={isSendingCode || didSendCode} onClick={sendCode}>
          {sendButtonText}
        </button>
        {error && <div className="error">{error}</div>}
      </React.Fragment>
    );
  };

  let renderCodeInput = function() {
    if (!didSendCode) return;

    return (
      <React.Fragment>
        <p>We've sent a 6 digit code to {user.email}.</p>
      </React.Fragment>
    );
  };

  return (
    <div className="otp-method otp-method-email">
      {renderSendCode()}
      {renderCodeInput()}
    </div>
  );
};

let SMSMethod = function({ user, didSendCode, setDidSendCode }) {
  let [isSendingCode, setIsSendingCode] = useState(false);
  let [phone, setPhone] = useState(user.phone_mobile || '');
  let [error, setError] = useState(null);

  let sendCode = useCallback(() => {
    setIsSendingCode(true);
    setError(null);

    axios.post('/setup/otp/send', { method: 'sms', phone })
      .then(() => {
        setDidSendCode(true);
        setIsSendingCode(false);
      })
      .catch(e => {
        setIsSendingCode(false);
        setError(e.response?.data?.error ?? 'Could not send verification message, please confirm your phone number is correct.')
      })
  }, [phone]);

  let renderSendCode = function() {
    if (didSendCode) return;

    let sendButtonText = isSendingCode ? 'Sending...' : 'Send me the code';
    return (
      <React.Fragment>
        <p>We will text with you with a 6 digit code, please confirm your mobile phone number.</p>
        <div className="input">
          <label htmlFor="phone">Mobile Phone Number</label>
          <input id="phone" type="tel" value={phone} onChange={e => { setPhone(e.currentTarget.value) }}/>
          {error && <div className="error">{error}</div>}
        </div>
        <button className="full-width" disabled={isSendingCode || didSendCode || phone.length === 0} onClick={sendCode}>
          {sendButtonText}
        </button>
      </React.Fragment>
    );
  };

  let renderCodeInput = function() {
    if (!didSendCode) return;

    return (
      <React.Fragment>
        <p>We've sent a 6 digit code to {phone}.</p>
      </React.Fragment>
    );
  };

  return (
    <div className="otp-method otp-method-sms">
      {renderSendCode()}
      {renderCodeInput()}
    </div>
  );
};

let AppMethod = function({ user, otp_app_uri, setDidSendCode }) {
  useEffect(() => {
    setDidSendCode(true);
  });

  return (
    <div className="otp-method otp-method-app">
      <p>
        Open the authenticator app on your phone add scan the QR code below. If you don't have an authenticator app,
        we recommend Google Authenticator (<a href="https://apps.apple.com/us/app/google-authenticator/id388497605">iOS</a>, <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_CA">Android</a>)
        or Authy (<a href="https://apps.apple.com/ca/app/authy/id494168017">iOS</a>, <a href="https://play.google.com/store/apps/details?id=com.authy.authy&hl=en_CA">Android</a>).
      </p>
      <a href={otp_app_uri}>
        <QRCode value={otp_app_uri} className="qr-code" size={192}/>
      </a>
    </div>
  );
};

let OtpSettings = function({ user, otp_app_uri }) {
  let [method, setMethod] = useState(user.otp_preferred_method);
  let [didSendCode, setDidSendCode] = useState(false);
  let [code, setCode] = useState('');
  let [isVerifying, setIsVerifying] = useState(false);
  let [error, setError] = useState(null);

  let verifyCode = useCallback(() => {
    setIsVerifying(true);
    axios.post('/setup/otp/verify', { method, code })
      .then(() => {
        setIsVerifying(false);
        window.location = '/';
      })
      .catch(e => {
        setIsVerifying(false);
        setError(e.response?.data?.error ?? 'There was a problem verifying your code, please try again later.')
      });
  }, [method, code]);

  let renderMethod = function() {
    if (method === "email") {
      return <EmailMethod user={user} didSendCode={didSendCode} setDidSendCode={setDidSendCode}/>
    } else if (method === "app") {
      return <AppMethod user={user} otp_app_uri={otp_app_uri} setDidSendCode={setDidSendCode}/>
    } else if (method === "sms") {
      return <SMSMethod user={user} didSendCode={didSendCode} setDidSendCode={setDidSendCode}/>
    }
    return null;
  };

  let renderCodeInput = function() {
    if (!didSendCode) return;

    let buttonText = isVerifying ? 'Verifying...' : 'Verify';
    return (
      <React.Fragment>
        <div className="input code-input">
          <label htmlFor="code-input">6 Digit Code</label>
          <input id="code-input" type="number" value={code} onChange={e => {
            setCode(e.currentTarget.value)
          }}/>
          {error && <div className="error">{error}</div>}
        </div>
        <button className="full-width" onClick={verifyCode} disabled={isVerifying}>{buttonText}</button>
      </React.Fragment>
    );
  };

  const methodOptions = [
    { label: 'Email Code', value: 'email' },
    { label: 'App Generated Code', value: 'app' },
    { label: 'SMS Code', value: 'sms' },
  ];

  return (
    <div id="otp-settings-page" className="page-container page-centered pure-u-1 pure-u-md-2-3 pure-u-lg-1-2 pure-u-xl-1-3">
      <h1>2-Factor Authentication</h1>
      <p>For your security, we require that you setup a form of 2-Factor Authentication.</p>

      <div className="input select">
        <SelectField label="Preferred 2FA Method" options={methodOptions} value={methodOptions.find(option => option.value === method)}
          onChange={(newMethod) => { setDidSendCode(false); setMethod(newMethod ? newMethod.value : newMethod); }}/>
      </div>
      {renderMethod()}
      {renderCodeInput()}
    </div>
  );
};

export default hot(module)(OtpSettings);
