import React, { useState } from 'react';
import useForm from 'react-hook-form';
import axios from 'axios';
import { hot } from 'react-hot-loader';
import Turbolinks from 'turbolinks';

import { FormTextField, FormSelectField } from '../shared/Inputs';

let Form = function({ user, initialBrokers, initialTags, initialTeams }) {
  const form = useForm({ defaultValues: user });
  const { register, handleSubmit, errors, getValues, setValue } = form;

  const [brokers, setBrokers] = useState(initialBrokers);
  const [teams, setTeams] = useState(initialTeams);
  const [tags, setTags] = useState(initialTags);
  const [isSaving, setSaving] = useState(false);
  const [serverError, setServerError] = useState(null);
  const [isCreatingBroker, setIsCreatingBroker] = useState(false);
  const [isCreatingTeam, setIsCreatingTeam] = useState(false);
  const [isCreatingTag, setIsCreatingTag] = useState(false);

  const onSubmit = data => {
    setSaving(true);
    setServerError(null);

    let result = user.id ? axios.patch(`/users/${user.id}.json`, { user: data }) : axios.post('/users.json', { user: data });
    result.then(response => {
        Turbolinks.visit(`/users/${response.data.id}`);
      })
      .catch(e => {
        setServerError(e.response?.data?.error ?? 'There was a problem saving the user, please try again later.');
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const createBroker = (brokerName) => {
    setIsCreatingBroker(true);

    axios.post('/brokers', { name: brokerName })
      .then(broker => {
        setBrokers(brokers.concat([broker.data]));
        setValue('broker_id', broker.data.id);
        setIsCreatingBroker(false);
      })
      .catch(e => {
        setServerError(e.response?.data?.error ?? 'There was a problem creating the broker, please try again later.');
        setIsCreatingBroker(false);
      });
  };

  const createTeam = (teamName) => {
    setIsCreatingTeam(true);

    axios.post('/teams', { name: teamName })
      .then(team => {
        setTeams(teams.concat([team.data]));
        setValue('team_id', team.data.id);
        setIsCreatingTeam(false);
      })
      .catch(e => {
        setServerError(e.response?.data?.error ?? 'There was a problem creating the team, please try again later.');
        setIsCreatingTeam(false);
      });
  };

  const createTag = (tagName) => {
    setIsCreatingTag(true);

    axios.post('/tags', { name: tagName })
      .then(tag => {
        setTags(tags.concat([tag.data]));
        setValue('tag_id', tag.data.id);
        setIsCreatingTag(false);
      })
      .catch(e => {
        setServerError(e.response?.data?.error ?? 'There was a problem creating the tag, please try again later.');
        setIsCreatingTag(false);
      });
  };

  let registerEmailConfirmation = () => {
    if (user.id) {
      return register({validate: value => getValues().email === user.email || value === getValues().email || 'must equal email' });
    } else {
      return register({required: 'This field is required', validate: value => value === getValues().email || 'must equal email' });
    }
  };

  let genderOptions = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Prefer not to say', value: 'unspecified' },
  ];

  let roleOptions = [{ label: 'Admin', value: 'admin' }];
  if (!user.id) roleOptions.unshift({ label: 'Agent', value: 'agent' }, { label: 'Marketing', value: 'marketing_user' });
  if (window.isSuperAdmin) roleOptions.push({ label: 'Super Admin', value: 'super_admin' });

  const brokerOptions = brokers.map(broker => ({ label: broker.name, value: broker.id }));
  const teamOptions = teams.map(team => ({ label: team.name, value: team.id }));
  const tagOptions = tags.map(tag => ({ label: tag.name, value: tag.id }));

  const activeOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  return (
    <div className="page-content">
      {serverError && <div className='error_notification'>{serverError}</div>}
      <form id="user-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-inputs pure-g">
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormTextField label="Email" name="email" type="email" reg={register({required: 'This field is required'})} errors={errors}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormTextField label="Email Confirmation" name="email_confirmation" type="email" reg={registerEmailConfirmation()} errors={errors}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormTextField label="First Name" name="first_name" reg={register({required: 'This field is required'})} errors={errors}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormTextField label="Last Name" name="last_name" reg={register({required: 'This field is required'})} errors={errors}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormSelectField label="Gender" name="gender" options={genderOptions} isCreatable={true} form={form} register={register({required: 'This field is required'})}/>
          </div>
          {(!user.id || ((user.role === 'admin' || user.role === 'super_admin') && window.isSuperAdmin)) && <div className="pure-u-1 pure-u-lg-1-2">
            <FormSelectField label="Account Type" name="role" options={roleOptions} form={form} register={register({required: 'This field is required'})}/>
          </div>}
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormTextField label="Office Phone Number" name="phone_office" type="tel" placeholder="(123) 456-7890" reg={register} errors={errors}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormTextField label="Cell Phone Number" name="phone_mobile" type="tel" placeholder="(123) 456-7890" reg={register} errors={errors}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormSelectField label="Brokerage" name="broker_id" options={brokerOptions} form={form}
              isCreatable={true} onCreateOption={createBroker} isLoading={isCreatingBroker} isClearable={true}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormSelectField label="Tag" name="tag_id" options={tagOptions} form={form}
              isCreatable={true} onCreateOption={createTag} isLoading={isCreatingTag} isClearable={true}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormSelectField label="Team" name="team_id" options={teamOptions} form={form}
              isCreatable={true} onCreateOption={createTeam} isLoading={isCreatingTeam} isClearable={true}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormSelectField label="Active" name="is_active" options={activeOptions} form={form} reg={register({required: 'This field is required'})}/>
          </div>
        </div>
        <div className="pure-g">
          <div className="pure-u-1 pure-u-lg-1-2">
            <input type="submit" disabled={isSaving} value={isSaving ? "Saving..." : user.id ? 'Save' : 'Create and Email'}/>
          </div>
        </div>
      </form>
    </div>
  );
};

export default hot(module)(Form);
