import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable'
import DatePicker from "react-datepicker";
import { add, sub } from 'date-fns';
import { TrixEditor } from 'react-trix';

let current = 0;
function generateId(prefix) {
  return `${prefix || 'id'}-${current++}`
}

let FormTextField = function({ errors, label, reg, type = 'text', name, ...props }) {
  let [id] = useState(generateId());
  let classes = classNames({
    'pure-u-23-24 input': true,
    'field_with_errors': errors[name],
  });

  return (
    <div className={classes}>
      <label htmlFor={id}>{label}</label>
      <input id={id} type={type} name={name} ref={reg} {...props}/>
      {errors[name] && <div className="error">{errors[name].message}</div>}
    </div>
  );
};

let SelectField = function({
  label,
  options,
  value,
  onChange,
  error = null,
  isClearable = null,
  isCreatable = false,
  onCreateOption = null,
  isLoading = false,
  isDisabled = false,
  isMulti = false,
  className = null,
}) {
  let primaryColor = error ? 'red' : '#C8B17A';
  let theme = theme => {
    return {
      ...theme,
      borderRadius: 0,
      colors: {
        ...theme.colors,
        primary: primaryColor,
        primary75: primaryColor,
        primary50: primaryColor,
        primary25: primaryColor,
      }
    };
  };

  let styles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'black',
      borderColor: primaryColor,
      borderWidth: '2px',
      boxShadow: null,
      minHeight: '30px',
      padding: '10px 0',
      '&:hover': {
        borderColor: primaryColor,
      },
      opacity: state.isDisabled ? 0.5 : 1,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 8px',
    }),
    placeholder: (provided) => ({
      ...provided,
      marginLeft: '6px',
    }),
    input: (provided) => ({
      ...provided,
      color: 'white',
      marginLeft: '6px',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
      marginLeft: '6px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'white',
      padding: '0 8px',
      '&:hover': {
        color: 'white',
      },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: 'white',
      padding: '0 8px',
      '&:hover': {
        color: 'white',
      },
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      border: `2px solid ${primaryColor}`,
      backgroundColor: 'black',
      top: '40px',
      borderTopWidth: '0',
      zIndex: '2',
    }),
    option: (provided, state) => ({
      ...provided,
      color: (state.isFocused || state.isSelected) ? 'black' : 'white',
      backgroundColor: state.isSelected ? '#C8B17A' : state.isFocused ? '#C8B17ACC' : null,
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: primaryColor,
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: 'black',
    }),
  };

  const classesObj = {
    'input': true,
    'field_with_errors': !!error,
  };
  classesObj[className] = !!className;
  let classes = classNames(classesObj);

  const RSelect = isCreatable ? CreatableSelect : Select;
  return (
    <div className={classes}>
      <label style={{pointerEvents: 'none', zIndex: 1}}>{label}</label>
      <RSelect theme={theme} styles={styles} options={options} isClearable={isClearable}
        onCreateOption={onCreateOption} isLoading={isLoading} isDisabled={isLoading || isDisabled}
        value={value} onChange={onChange} isMulti={isMulti}/>
      {error && <div className="error">{error}</div>}
    </div>
  );
};

let FormSelectField = function({
  label,
  name,
  options,
  form,
  register = form.register,
  isClearable = null,
  isCreatable = false,
  onCreateOption = null,
  isLoading = false,
  isDisabled = false,
}) {
  const { errors, watch, setValue, clearError } = form;

  useEffect(() => {
    register({ name });
  }, []);

  const selected = watch(name);
  const value = useMemo(() => {
    return options.find(option => option.value === selected);
  }, [options, selected]);

  return <SelectField label={label} options={options} isClearable={isClearable} isCreatable={isCreatable} onCreateOption={onCreateOption}
    isLoading={isLoading} value={value} onChange={option => { clearError(name); setValue(name, option ? option.value : option) }}
    error={errors[name] && errors[name].message} isDisabled={isDisabled} className="pure-u-23-24"/>;
};

const parseDate = (input, isLocal) => {
  if (!input) return null;
  if (input instanceof Date) return input;

  const date = new Date(input);
  if (isLocal) {
    const offset = date.getTimezoneOffset() / 60;
    date.setHours(date.getHours() + offset);
  }
  return date;
};

let FormDatePicker = ({
  label,
  name,
  form,
  selectTime = false,
  selectsStart = false,
  selectsEnd = false,
  startName = null,
  endName = null,
  autoEndDuration = { hours: 1 },
  isDisabled = false,
  register = form.register,
  className = null,
  placeHolder = "Select a date",
}) => {
  const { errors, watch, setValue, clearError } = form;
  const error = errors[name];

  useEffect(() => {
    register({ name });
  }, []);

  const selected = watch(name);
  const value = useMemo(() => {
    return parseDate(selected, !selectTime);
  }, [selected]);

  const startDate = (selectsStart && value) || (selectsEnd && parseDate(watch(startName))) || null;
  const endDate = (selectsStart && parseDate(watch(endName))) || (selectsEnd && value) || null;

  const handleChange = (date) => {
    clearError(name);
    setValue(name, date);
    if (autoEndDuration && selectsStart && (!endDate || endDate < date)) {
      setValue(endName, add(date, autoEndDuration));
    }
  };

  const classesObj = {
    'input pure-u-23-24': true,
    'field_with_errors': !!error,
  };
  classesObj[className] = !!className;
  let classes = classNames(classesObj);

  const dateFormat = selectTime ? 'MM/dd/yyyy h:mm aa' : 'MM/dd/yyyy';

  return (
    <div className={classes}>
      <label style={{pointerEvents: 'none', zIndex: 1}}>{label}</label>
      <DatePicker selected={value} onChange={handleChange}
        showTimeSelect={selectTime} timeFormat="h:mm aa" timeIntervals={15} dateFormat={dateFormat}
        selectsStart={selectsStart} selectsEnd={selectsEnd} startDate={startDate} endDate={endDate} minDate={selectsEnd && startDate || null}
        placeholderText={placeHolder} disabled={isDisabled}/>
      {error && <div className="error">{error.message}</div>}
    </div>
  );
};

const DateRangeSelector = ({
  onChange,
  onChangeType,
  defaultType = { label: 'Last 30 days', value: 'last30' },
  controlledType,
  children,
}) => {
  const [ type, setType ] = useState(controlledType || defaultType);
  const [ startDate, setStartDate ] = useState(null);
  const [ endDate, setEndDate ] = useState(null);

  const dateOptions = useMemo(() => {
    return [
      { label: 'Today', value: 'today' },
      { label: 'Last 7 days', value: 'last7' },
      { label: 'Last 30 days', value: 'last30' },
      { label: 'Year-to-date', value: 'ytd' },
      { label: 'Last Year', value: 'lastYear' },
      { label: 'Custom', value: 'custom' },
    ];
  }, []);

  const dateFormat = 'MMMM d, yyyy';

  const handleTypeChange = (newType) => {
    setType(newType);
    let newStartDate = startDate;
    let newEndDate = endDate;
    if (newType.value === 'today') {
      newStartDate = new Date();
      newEndDate = new Date();
    } else if (newType.value === 'last7') {
      newStartDate = sub(new Date(), { days: 7 });
      newEndDate = new Date();
    } else if (newType.value === 'last30') {
      newStartDate = sub(new Date(), { days: 30 });
      newEndDate = new Date();
    } else if (newType.value === 'ytd') {
      newStartDate = new Date(new Date().getFullYear(), 0, 1);
      newEndDate = new Date();
    } else if (newType.value === 'lastYear') {
      newStartDate = new Date(new Date().getFullYear() - 1, 0, 1);
      newEndDate = new Date(new Date().getFullYear() - 1, 11, 31);
    }
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    onChange({ startDate: newStartDate, endDate: newEndDate });
  };

  useEffect(() => {
    !controlledType && handleTypeChange(type);
  }, []);

  useEffect(() => {
    controlledType && handleTypeChange(controlledType);
  }, [controlledType]);

  const handleStartChange = (date) => {
    setStartDate(date);
    onChange({ startDate: date, endDate: endDate });
  };

  const handleEndChange = (date) => {
    setEndDate(date);
    onChange({ startDate: startDate, endDate: date });
  };

  const renderDateInputs = () => {
    if (type.value !== 'custom') return;
    return (
      <>
        <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-3 pure-u-xl-1-4 form-padded">
          <div className="input">
            <label style={{pointerEvents: 'none', zIndex: 1}}>Start Date</label>
            <DatePicker selected={startDate} onChange={handleStartChange} dateFormat={dateFormat}
              selectsStart startDate={startDate} endDate={endDate}/>
          </div>
        </div>
        <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-3 pure-u-xl-1-4 form-padded">
          <div className="input">
            <label style={{pointerEvents: 'none', zIndex: 1}}>End Date</label>
            <DatePicker selected={endDate} onChange={handleEndChange} dateFormat={dateFormat}
              selectsEnd startDate={startDate} endDate={endDate} minDate={startDate}/>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="pure-g">
      {children}
      <div className="pure-u-1 pure-u-lg-1-3 pure-u-xl-1-4 form-padded">
        <SelectField label="Date Range" options={dateOptions} value={type} onChange={controlledType && onChangeType || handleTypeChange}/>
      </div>
      {renderDateInputs()}
    </div>
  );
};

const RichTextField = ({
  form,
  name,
  register = form.register,
}) => {
  const { errors, watch, setValue, clearError } = form;
  const error = errors[name];

  useEffect(() => {
    register({ name });
  }, []);

  const value = watch(name);

  const handleChange = (html) => {
    clearError(name);
    setValue(name, html);
  };

  return (
    <>
      <TrixEditor value={value} onChange={handleChange} fileParamName="blob" uploadURL="/files/direct_uploads"/>
      {error && <div className="error">{error.message}</div>}
    </>
  );
};

export { FormTextField, SelectField, FormSelectField, FormDatePicker, DateRangeSelector, RichTextField };
