import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useTable, usePagination, useSortBy, useFilters } from 'react-table/src/index';
import { format, add } from 'date-fns';
import { SelectField, DateRangeSelector } from '../shared/Inputs';
import axios from 'axios';

import { hot } from 'react-hot-loader';

const EventsTable = ({ data, fetchData, totalPages }) => {
  const columns = useMemo(() => {
    return [
      // HIDDEN COLUMNS
      {
        Header: '__id',
        accessor: 'id',
      },
      // VISIBLE COLUMNS
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Start Date',
        accessor: 'start_date',
        width: '5%',
        Cell: ({ cell: { value }}) => {
          return format(new Date(value), 'MMMM d, yyyy h:mm a');
        },
        disableGlobalFilter: true,
      },
      {
        Header: 'End Date',
        accessor: 'end_date',
        width: '5%',
        Cell: ({ cell: { value }}) => {
          return format(new Date(value), 'MMMM d, yyyy h:mm a');
        },
        disableGlobalFilter: true,
      },
      {
        Header: 'Location',
        accessor: 'location',
      },
      {
        Header: 'Project',
        accessor: 'project_name',
        Cell: ({ cell: { value }}) => {
          return value || '-';
        }
      },
      {
        Header: 'RSVPs',
        accessor: 'rsvp_count',
        width: '5%',
        disableGlobalFilter: true,
      },
      {
        Header: 'Actions',
        id: 'actions',
        width: '20%',
        Cell: ({ data, row }) => {
          const obj = data[row.index];
          return (
            <span className='table-actions'>
              <a href={`/messages/${obj.id}`}>View</a>
              <a href={`/messages/${obj.id}/edit`}>Edit</a>
              {window.isSuperAdmin && <a href={`/messages/${obj.id}`} data-method="DELETE" data-confirm="Are you sure?">Delete</a>}
            </span>
          );
        },
      }
    ];
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // Filtering
    setFilter,
    // Pagination
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, filters, sortBy },
  } = useTable({
    columns,
    data,
    initialState: {
      hiddenColumns: ['id'],
      pageIndex: 0,
      pageSize: 25,
      sortBy: [{ id: 'time', desc: true }],
    },
    // Pagination
    manualPagination: true,
    pageCount: totalPages,
    // Filtering
    manualFilters: true,
    // Sorting
    manualSortBy: true,
    disableMultiSort: true,
    disableSortRemove: true,
    disableSortBy: true,
  }, useFilters, useSortBy, usePagination);

  useEffect(() => {
    fetchData({ pageIndex, pageSize, filters, sortBy });
  }, [fetchData, pageIndex, pageSize, filters, sortBy]);

  const renderPagination = () => {
    return (
      <div className="table-nav">
        <div><a className={canPreviousPage && 'enabled' || 'disabled'} onClick={() => gotoPage(0)}>First</a></div>
        <div><a className={canPreviousPage && 'enabled' || 'disabled'} onClick={() => previousPage()}>Previous</a></div>
        <div className="page-info">Page {pageIndex + 1} of {pageCount}</div>
        <div><a className={canNextPage && 'enabled' || 'disabled'} onClick={() => nextPage()}>Next</a></div>
        <div><a className={canNextPage && 'enabled' || 'disabled'} onClick={() => gotoPage(pageCount - 1)}>Last</a></div>
      </div>
    );
  };

  const renderSortIcon = (column) => {
    if (column.isSorted) {
      const icon = column.isSortedDesc ? 'keyboard_arrow_down' : 'keyboard_arrow_up';
      return <i className="material-icons">{icon}</i>;
    } else {
      return null;
    }
  };

  return (
    <>
      <table className="pure-table full-width" {...getTableProps()}>
        <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps({ style: { width: null } }))}>
                {column.render('Header')}
                {renderSortIcon(column)}
              </th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
        {!page.length && (
          <tr>
            <td colSpan={1000}>No Results</td>
          </tr>
        )}
        </tbody>
      </table>
      {renderPagination()}
    </>
  );
};

const EventsPage = ({ agents }) => {
  const [ data, setData ] = useState([]);
  const [ totalPages, setTotalPages ] = useState(0);

  const fetchData = useCallback(({ filters, pageIndex, pageSize, sortBy }) => {
    const { id: sortColumn, desc: sortDesc } = (sortBy && sortBy[0]) || {};
    axios.get('/events.json', {
      params: {
        page: pageIndex,
        page_size: pageSize,
        sort_column: sortColumn,
        sort_desc: sortDesc,
      }
    }).then((response) => {
      setData(response.data.events);
      setTotalPages(Math.ceil(response.data.total / pageSize));
    })
  }, []);

  return (
    <EventsTable data={data} totalPages={totalPages} agents={agents} fetchData={fetchData}/>
  );
};

export default hot(module)(EventsPage);
