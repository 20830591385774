import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useTable, usePagination, useSortBy, useFilters, useGroupBy, useExpanded } from 'react-table/src/index';
import { uniqueCount, sum, average, count, min, max } from 'react-table/src/aggregations';
import { add, format } from 'date-fns';
import { CSVLink } from "react-csv";
import { SelectField, DateRangeSelector } from '../shared/Inputs';
import axios from 'axios';

const BlacklineIcon = () => {
  return (
    <span className="blackline-icon" title="Synced from Blackline">
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 130 130">
        <path d="M38.6 24.7c-13.2 7.6-24.2 14.4-24.4 15-.2.7 2.6 3.1 6.2 5.4 3.6 2.3 6.6 4.3 6.6 4.4 0 .2-2.7 1.8-6 3.7-3.7 2-6 3.9-6 5 0 1 2.2 2.9 5.5 4.8 3 1.7 5.7 3.4 5.9 3.8.2.4-2.2 2.3-5.5 4.2-3.2 1.9-5.9 4-5.9 4.7 0 .8 2.7 3.1 6 5.1l6 3.8-6.2 3.5c-4.4 2.4-6.3 4-6.3 5.4 0 1.4 5.7 5.2 23.8 15.7 13.1 7.6 24.4 13.8 25.2 13.8 1.9 0 2.5-2.3 2.5-9.1 0-3.2.3-5.9.6-5.9.4 0 2.9 1.3 5.6 3 2.6 1.6 5.7 3 6.8 3 1.8 0 2-.7 2-7.5 0-4.1.3-7.5.6-7.5.4 0 3.3 1.6 6.6 3.6 3.4 2.1 6.3 3.3 6.8 2.8s1-4.4 1.2-8.6l.3-7.7 5.5 3.4c3 1.9 6.5 3.5 7.8 3.5h2.2V67.5c0-23.9-.2-28.7-1.4-29.1-.8-.3-4.2 1-7.6 3-3.4 2-6.3 3.6-6.6 3.6-.2 0-.4-3.6-.4-8 0-9.4-.2-9.6-8.7-4.6l-5.8 3.4-.3-7.4c-.2-5.1-.7-7.7-1.6-8-.7-.3-4 1.1-7.2 3l-5.9 3.4-.5-7.6c-.4-5.8-.9-7.7-2-7.9-.8-.2-12.3 5.9-25.4 13.4zm21.7 5.4c-.4.4-6.8 4.2-14.1 8.4l-13.4 7.7-4.6-2.5c-2.6-1.4-4.8-2.9-4.9-3.4-.2-.4 8.2-5.6 18.5-11.5l18.7-10.7.3 5.6c.2 3.1-.1 5.9-.5 6.4zm14.1 9.6c-.9.6-7.2 4.4-14 8.3L48 55.2l-5.1-3-5.1-2.9 18.9-10.9 18.8-10.9.3 5.4c.2 4.3-.1 5.8-1.4 6.8zM92 41.9v5.9L77.6 56l-14.5 8.1-4.5-2.6C56 60 54 58.5 54 58.2c.1-.6 36.8-22.2 37.7-22.2.2 0 .3 2.7.3 5.9zm14.6 46.8c-.2.2-2.7-1-5.5-2.7L96 82.8V51.2l5.3-3 5.2-2.9.3 21.5c.1 11.8.1 21.7-.2 21.9zM51 63l9.5 5.6.3 5.7c.2 3.1 0 5.7-.3 5.7s-8.9-4.8-19-10.7C30.1 62.7 23.4 58.2 24 57.6c.5-.5 2.6-1.9 4.7-3.1l3.8-2.2 4.5 2.6c2.5 1.4 8.8 5 14 8.1zm41 13.5v22.4l-5.5-3.2-5.5-3.2.3-16.4.2-16.5 4.5-2.7c2.5-1.5 4.8-2.8 5.3-2.8.4-.1.7 10 .7 22.4zm-16 8.6v22l-5-2.8-5-2.8v-33l4.8-2.7c2.6-1.5 4.8-2.8 5-2.8.1 0 .2 9.9.2 22.1zm-23.7-3.6l8.7 5v5.7c0 3.2-.2 5.8-.4 5.8-.4 0-30.2-17.4-35.5-20.7l-2.4-1.5 4.9-2.8 4.8-2.9 5.6 3.2c3 1.8 9.5 5.4 14.3 8.2zM47 96.1l14 8.2v5.9c0 5.8 0 5.9-2.2 4.7C53.1 112 22 93.8 22 93.4c0-.5 9.2-5.3 10.3-5.4.4 0 7 3.7 14.7 8.1z" fill="currentColor"/>
      </svg>
    </span>
  );
}

import { hot } from 'react-hot-loader';

const oneOfFilter = (rows, ids, filterValue) => {
  return rows.filter((row) => {
    return ids.some((id) => {
      const rowValue = row.values[id];
      return !filterValue || filterValue.length === 0 || filterValue.includes(rowValue);
    });
  });
};

const countEach = (values) => {
  const obj = {};
  values.forEach(value => {
    obj[value] && (obj[value] += 1) || (obj[value] = 1);
  });
  return obj;
};

const groupedHeaderName = ({ state: { groupBy }, column: { isGrouped, headerName } }) => {
  return headerName(!isGrouped && groupBy && groupBy.length, false, groupBy);
};

const numberFormat = new Intl.NumberFormat('en-US', {});

const DownloadButton = ({ rows, prepareRow, groupBy, preGroupedRows, preExpandedRows }) => {
  const data = useMemo(() => {
    return rows.filter(row => !row.isExpanded).map(row => {
      prepareRow(row);
      let obj = {};
      row.cells.forEach(cell => {
        if (cell.column.id !== 'actions') {
          obj[cell.column.headerName(groupBy.length && !cell.column.isGrouped, true)] =
            cell.column.csvValue && cell.column.csvValue({ cell, row, preGroupedRows, preExpandedRows, state: { groupBy }}) || cell.value;
        }
      });
      return obj;
    });
  }, [rows]);

  return (
    <>
      <CSVLink className="button" filename="deals.csv" data={data}>Export CSV</CSVLink>
    </>
  );
};

const DealsTable = ({
  data,
  fetchData,
  agents,
  projects,
  brokers,
  teams,
  suiteNames,
  suiteTypes,
  incentives,
  goal1,
  goal2,
}) => {
  const activeProjectCount = useMemo(() => { return projects.filter(project => project.is_active).length; }, [projects]);

  const columns = useMemo(() => {
    return [
      // HIDDEN COLUMNS
      {
        Header: '__project_name',
        accessor: 'project_name',
      },
      {
        Header: '__agent_name',
        accessor: 'agent_name',
      },
      {
        Header: '__broker_id',
        accessor: 'broker_id',
        filter: oneOfFilter,
      },
      {
        Header: '__team_id',
        accessor: 'team_id',
        filter: oneOfFilter,
      },
      {
        Header: '__incentive_id',
        accessor: 'incentive_id',
        filter: oneOfFilter,
      },
      // VISIBLE COLUMNS
      {
        Header: groupedHeaderName,
        headerName: (isGrouped) => isGrouped ? 'Deals' : 'Deal Number',
        accessor: 'id',
        width: '5%',
        disableGroupBy: true,
        aggregate: 'count',
        Cell: ({ cell: { value }, data, row, state: { groupBy } }) => {
          const obj = data[row.index];
          const icon = (!groupBy.length && obj.blackline_deal_id) ? <BlacklineIcon/> : null;
          return (<>{value} {icon}</>);
        },
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped, isCsv) => {
          return isCsv && 'Percentage' || '%'
        },
        id: 'percentage',
        Cell: (props) => props.cell.column.csvValue(props),
        csvValue: ({ row, preGroupedRows, preExpandedRows }) => {
          let percent;
          if (row.id.indexOf('>') >= 0) { // Second level group
            const parentId = row.id.split('>')[0];
            const parentRow = preExpandedRows.find((r) => r.id === parentId);
            percent = row.values.id / parentRow.values.id;
          } else {
            percent = row.values.id / preGroupedRows.length;
          }
          return `${Math.round(percent * 100)}%`;
        },
        disableGroupBy: true,
        disableSortBy: true,
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped) => isGrouped ? 'Earliest Date' : 'Date',
        accessor: 'date',
        width: '10%',
        sortDescFirst: true,
        Cell: ({ cell: { value }}) => {
          let date = new Date(value);
          date = add(date, { minutes: date.getTimezoneOffset() });
          return format(date, "MMMM d, yyyy");
        },
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped) => isGrouped ? 'Projects' : 'Project',
        accessor: 'project_id',
        width: '10%',
        Cell: ({ cell: { value }}) => {
          return (
            <a href={`/projects/${value}`}>{projects.find(project => project.id === value).name}</a>
          );
        },
        csvValue: ({ cell: { value, isAggregated } }) => isAggregated ? value : projects.find(project => project.id === value).name,
        aggregate: 'uniqueCount',
        Aggregated: ({ cell: { value }}) => value,
        filter: oneOfFilter,
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped, isCsv) => isCsv ? 'Avg Sold per Project' : 'Avg per Project',
        accessor: 'id',
        id: 'avg_per_project',
        Cell: ({ cell: { value }}) => {
          return Math.round(value * 10 / activeProjectCount) / 10;
        },
        csvValue: ({ cell: { value } }) => {
          return Math.round(value * 10 / activeProjectCount) / 10;
        },
        aggregate: 'count',
        disableGroupBy: true,
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped) => isGrouped ? 'Agents' : 'Agent',
        accessor: 'agent_id',
        width: '10%',
        Cell: ({ cell: { value } }) => {
          return (
            <a href={`/users/${value}`}>{agents.find(agent => agent.id === value).name}</a>
          );
        },
        csvValue: ({ cell: { value, isAggregated } }) => isAggregated ? value : agents.find(agent => agent.id === value).name,
        aggregate: 'uniqueCount',
        Aggregated: ({ cell: { value }}) => value,
        filter: oneOfFilter,
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped, isCsv) => 'Top Tier',
        accessor: (data) => data.agent_powerboost_total_completed > goal2 ? data.agent_id : null,
        id: 'top_tier',
        Cell: (props) => props.cell.column.csvValue(props),
        csvValue: ({ cell: { value }, row, preGroupedRows, preExpandedRows }) => {
          const outOf = row.values.agent_id;
          const percent = value / outOf;
          return `${value}/${outOf} (${Math.round(percent * 100)}%)`;
        },
        aggregate: (values) => {
          return new Set(values.filter(i => !!i)).size;
        },
        disableGroupBy: true,
        disableSortBy: true,
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped, isCsv, groupBy) => isGrouped && (!groupBy?.length || groupBy.indexOf('agent_id') === -1) ? 'Brokerages' : 'Brokerage',
        accessor: 'broker_name',
        width: '10%',
        Cell: ({ cell, state, row }) => cell.column.csvValue({ cell, state, row }),
        csvValue: ({ cell: { value, isGrouped }, state: { groupBy }, row: { depth }}) => {
          if (isGrouped) {
            return value || 'No Brokerage';
          } else if (groupBy.length) {
            if (typeof value === "string") return value;
            return (groupBy[depth] === 'agent_id' || groupBy[0] === 'agent_id') ? (value?.values && value.values().next().value || '-') : (value?.size || '-');
          } else {
            return value || '-';
          }
        },
        aggregate: (values) => {
          return new Set(values.filter(i => !!i));
        },
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped, isCsv, groupBy) => isGrouped && (!groupBy?.length || groupBy.indexOf('agent_id') === -1) ? 'Teams' : 'Team',
        accessor: 'team_name',
        width: '10%',
        Cell: ({ cell, state, row }) => cell.column.csvValue({ cell, state, row }),
        csvValue: ({ cell: { value, isGrouped }, state: { groupBy }, row: { depth }}) => {
          if (isGrouped) {
            return value || 'No Team';
          } else if (groupBy.length) {
            if (typeof value === "string") return value;
            return (groupBy[depth] === 'agent_id' || groupBy[0] === 'agent_id') ? (value?.values && value.values().next().value || '-') : (value?.size || '-');
          } else {
            return value || '-';
          }
        },
        aggregate: (values) => {
          return new Set(values.filter(i => !!i));
        },
      },
      {
        Header: groupedHeaderName,
        headerName: () => 'Suite',
        accessor: 'suite_label',
        disableGroupBy: true,
        Cell: ({ cell: { value }}) => {
          return value || '-';
        },
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped, isCsv) => {
          return isCsv ? 'Firm' : (<i className="material-icons" title="Firm">check</i>);
        },
        id: 'status_completed',
        accessor: 'status',
        aggregate: (values) => values.filter(v => v === 'completed').length,
        disableGroupBy: true,
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped, isCsv) => {
          return isCsv && 'Firm Sales' || (<><i className="material-icons" title="Total Firm Sales">check</i></>)
        },
        accessor: 'price_completed',
        disableGroupBy: true,
        Cell: ({ cell: { value }}) => {
          return `$${numberFormat.format(Math.round(value / 100))}`;
        },
        csvValue: ({ cell: { value }}) => numberFormat.format(Math.round(value / 100)),
        aggregate: 'sum',
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped, isCsv) => {
          return isCsv ? 'Pending' : (<i className="material-icons" title="Pending">hourglass_empty</i>);
        },
        id: 'status_pending',
        accessor: 'status',
        aggregate: (values) => values.filter(v => v === 'pending').length,
        disableGroupBy: true,
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped, isCsv) => {
          return isCsv && 'Pending Sales' || (<><i className="material-icons" title="Total Pending Sales">hourglass_empty</i></>)
        },
        accessor: 'price_pending',
        disableGroupBy: true,
        Cell: ({ cell: { value }}) => {
          return `$${numberFormat.format(Math.round(value / 100))}`;
        },
        csvValue: ({ cell: { value }}) => numberFormat.format(Math.round(value / 100)),
        aggregate: 'sum',
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped, isCsv) => {
          return isCsv ? 'Rescinded' : (<i className="material-icons" title="Rescinded">close</i>);
        },
        id: 'status_rescinded',
        accessor: 'status',
        aggregate: (values) => values.filter(v => v === 'rescinded').length,
        disableGroupBy: true,
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped, isCsv) => {
          return isCsv && 'Rescinded Sales' || (<><i className="material-icons" title="Total Rescinded Sales">close</i></>)
        },
        accessor: 'price_rescinded',
        disableGroupBy: true,
        Cell: ({ cell: { value }}) => {
          return `$${numberFormat.format(Math.round(value / 100))}`;
        },
        csvValue: ({ cell: { value }}) => numberFormat.format(Math.round(value / 100)),
        aggregate: 'sum',
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped) => 'Suite Type',
        accessor: 'suite_type',
        width: '10%',
        Cell: ({ cell: { value }}) => {
          return suiteNames[value];
        },
        csvValue: ({ cell: { value }}) => {
          return suiteNames[value];
        },
        sortType: (rowA, rowB, columnId, desc) => {
          return suiteTypes[rowA.values.suite_type] - suiteTypes[rowB.values.suite_type];
        },
        filter: oneOfFilter,
        Aggregated: () => {
          return null;
        },
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped, isCsv) => {
          return isCsv && 'Studio' || (<div><i className="material-icons">single_bed</i><div className="suite-type">0</div></div>);
        },
        id: 'suite_type_studio',
        accessor: 'suite_type',
        aggregate: (values) => values.filter(v => v === 'studio').length,
        disableGroupBy: true,
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped, isCsv) => {
          return isCsv && '1 Bed' || (<div><i className="material-icons">single_bed</i><div className="suite-type">1</div></div>);
        },
        id: 'suite_type_bed1',
        accessor: 'suite_type',
        aggregate: (values) => values.filter(v => v === 'bed1').length,
        disableGroupBy: true,
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped, isCsv) => {
          return isCsv && '1 Bed + Den' || (<div><i className="material-icons">single_bed</i><div className="suite-type">1+1</div></div>);
        },
        id: 'suite_type_bed1_den',
        accessor: 'suite_type',
        aggregate: (values) => values.filter(v => v === 'bed1_den').length,
        disableGroupBy: true,
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped, isCsv) => {
          return isCsv && '2 Bed' || (<div><i className="material-icons">single_bed</i><div className="suite-type">2</div></div>);
        },
        id: 'suite_type_bed2',
        accessor: 'suite_type',
        aggregate: (values) => values.filter(v => v === 'bed2').length,
        disableGroupBy: true,
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped, isCsv) => {
          return isCsv && '2 Bed + Den' || (<div><i className="material-icons">single_bed</i><div className="suite-type">2+1</div></div>);
        },
        id: 'suite_type_bed2_den',
        accessor: 'suite_type',
        aggregate: (values) => values.filter(v => v === 'bed2_den').length,
        disableGroupBy: true,
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped, isCsv) => {
          return isCsv && '3 Bed' || (<div><i className="material-icons">single_bed</i><div className="suite-type">3</div></div>);
        },
        id: 'suite_type_bed3',
        accessor: 'suite_type',
        aggregate: (values) => values.filter(v => v === 'bed3').length,
        disableGroupBy: true,
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped, isCsv) => {
          return isCsv && '3 Bed + Den' || (<div><i className="material-icons">single_bed</i><div className="suite-type">3+1</div></div>);
        },
        id: 'suite_type_bed3_den',
        accessor: 'suite_type',
        aggregate: (values) => values.filter(v => v === 'bed3_den').length,
        disableGroupBy: true,
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped, isCsv) => {
          return isCsv && 'Penthouse' || (<div><i className="material-icons">single_bed</i><div className="suite-type">PH</div></div>);
        },
        id: 'suite_type_penthouse',
        accessor: 'suite_type',
        aggregate: (values) => values.filter(v => v === 'penthouse').length,
        disableGroupBy: true,
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped) => isGrouped ? 'Avg Sq Ft' : 'Sq Ft',
        accessor: 'sq_ft',
        width: '5%',
        Cell: ({ cell: { value }}) => {
          return numberFormat.format(Math.round(value));
        },
        csvValue: ({ cell: { value }}) => numberFormat.format(Math.round(value)),
        disableGroupBy: true,
        filter: 'between',
        aggregate: 'average',
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped) => isGrouped ? 'Total Sales' : 'Price',
        accessor: 'price',
        width: '10%',
        disableGroupBy: true,
        Cell: ({ cell: { value }}) => {
          return `$${numberFormat.format(Math.round(value / 100))}`;
        },
        csvValue: ({ cell: { value }}) => numberFormat.format(Math.round(value / 100)),
        filter: 'between',
        aggregate: 'sum',
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped) => isGrouped ? 'Total Sales (Powerboost)' : 'Powerboost Price',
        accessor: 'powerboost_price',
        width: '10%',
        disableGroupBy: true,
        Cell: ({ data, row, cell: { value }, state: { groupBy }}) => {
          const obj = data[row.index];
          const multiplier = groupBy.length ? '$' : `${parseFloat(obj.powerboost_multiplier)}x`;
          const multiplierSpan = value > obj.price ? (<span className="powerboost-multiplier">{multiplier}</span>) : null;
          return (<>${numberFormat.format(Math.round(value / 100))}{multiplierSpan}</>);
        },
        csvValue: ({ cell: { value }}) => numberFormat.format(Math.round(value / 100)),
        filter: 'between',
        aggregate: 'sum',
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped) => 'Avg Price',
        id: 'average_price',
        accessor: 'price',
        width: '10%',
        disableGroupBy: true,
        Cell: ({ cell: { value }}) => {
          return `$${numberFormat.format(Math.round(value / 100))}`;
        },
        csvValue: ({ cell: { value }}) => numberFormat.format(Math.round(value / 100)),
        aggregate: 'average',
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped, isCsv) => `${isGrouped ? 'Avg ' : ''}${isCsv ? 'Price' : '$'}/Sq Ft`,
        id: 'pp_sq_ft',
        accessor: (data) => data.price / data.sq_ft,
        width: '10%',
        disableGroupBy: true,
        Cell: ({ cell: { value }}) => {
          return `$${numberFormat.format(Math.round(value / 100))}`;
        },
        csvValue: ({ cell: { value }}) => numberFormat.format(Math.round(value / 100)),
        aggregate: 'average',
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped) => isGrouped ? 'Incentives' : 'Incentive',
        accessor: 'incentive_name',
        Cell: ({ cell: { value, isGrouped }}) => {
          return value || (isGrouped ? 'No Incentive' : '-');
        },
        aggregate: (values) => {
          return uniqueCount(values.filter(i => !!i));
        },
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped) => 'Status',
        accessor: 'status',
        width: '10%',
        filter: oneOfFilter,
        aggregate: countEach,
        Aggregated: ({ cell: { value }}) => {
          return (
            <>
              Firm: {value.completed || 0}<br/>
              Pending: {value.pending || 0}<br/>
              Rescinded: {value.rescinded || 0}
            </>
          );
        },
      },
      {
        Header: groupedHeaderName,
        headerName: (isGrouped) => 'Actions',
        id: 'actions',
        width: '10%',
        disableGroupBy: true,
        Cell: ({ data, row }) => {
          const obj = data[row.index];
          return (
            <span className='table-actions'>
              <a href={`/deals/${obj.id}`}>View</a>
              <a href={`/deals/${obj.id}/edit`}>Edit</a>
              {window.isSuperAdmin && <a href={`/deals/${obj.id}`} data-method="DELETE" data-confirm="Are you sure?">Delete</a>}
            </span>
          );
        },
        Aggregated: () => {
          return null;
        },
      }
    ];
  }, []);

  const skipResetRef = useRef(false);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    toggleHideColumn,
    rows,
    // Filtering
    setFilter,
    preFilteredRows,
    setAllFilters,
    // Sorting
    preSortedRows,
    // Grouping
    preGroupedRows,
    toggleGroupBy,
    // Expanding
    preExpandedRows,
    toggleRowExpanded,
    toggleAllRowsExpanded,
    // Pagination
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, groupBy, expanded },
  } = useTable({
    columns,
    data,
    initialState: {
      hiddenColumns: ['project_name', 'agent_name', 'broker_id', 'team_id', 'incentive_id', 'average_price', 'top_tier'],
      pageIndex: 0,
      pageSize: 50,
      sortBy: [{ id: 'date', desc: true }],
    },
    // Sorting
    disableMultiSort: true,
    disableSortRemove: true,
    // Resetting
    // autoResetPage: !skipResetRef.current && false,
    autoResetExpanded: !skipResetRef.current && false,
    autoResetGroupBy: !skipResetRef.current && false,
    // autoResetSelectedRows: !skipResetRef.current && false,
    autoResetSortBy: !skipResetRef.current && false,
    autoResetFilters: !skipResetRef.current && false,
    // autoResetRowState: !skipResetRef.current && false,
  }, useFilters, useGroupBy, useSortBy, useExpanded, usePagination);

  const isAnyGrouped = groupBy && groupBy.length && groupBy.length > 0;

  const [report, setReport] = useState(null);
  const [dateType, setDateType] = useState({ label: 'Last 30 days', value: 'last30' });
  const [agentFilter, setAgentFilter] = useState([]);
  const [projectFilter, setProjectFilter] = useState([]);
  const [brokerFilter, setBrokerFilter] = useState([]);
  const [teamFilter, setTeamFilter] = useState([]);
  const [suiteTypeFilter, setSuiteTypeFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [incentiveFilter, setIncentiveFilter] = useState([]);
  const [sqFtFilter, setSqFtFilter] = useState([null, null]);
  const [priceFilter, setPriceFilter] = useState([null, null]);

  const statusFilterValues = useMemo(() => (statusFilter || []).map((option) => option.value), [statusFilter]);
  const suiteFilterValues = useMemo(() => (suiteTypeFilter || []).map((option) => option.value), [suiteTypeFilter]);

  useEffect(() => {
    toggleHideColumn('date', isAnyGrouped && groupBy.indexOf('date') === -1);
    toggleHideColumn('percentage', !isAnyGrouped);
    toggleHideColumn('avg_per_project', !isAnyGrouped || groupBy.length !== 1 || groupBy[0] !== 'agent_id');
    toggleHideColumn('price', isAnyGrouped && groupBy.indexOf('status') === -1);
    toggleHideColumn('powerboost_price', isAnyGrouped && groupBy.indexOf('status') === -1);
    toggleHideColumn('average_price', !isAnyGrouped);
    toggleHideColumn('price_pending', !isAnyGrouped || groupBy.indexOf('status') >= 0 || (statusFilterValues.length && statusFilterValues.indexOf('pending') === -1));
    toggleHideColumn('price_completed', !isAnyGrouped || groupBy.indexOf('status') >= 0 || (statusFilterValues.length && statusFilterValues.indexOf('completed') === -1));
    toggleHideColumn('price_rescinded', !isAnyGrouped || groupBy.indexOf('status') >= 0 || (statusFilterValues.length && statusFilterValues.indexOf('rescinded') === -1));
    toggleHideColumn('actions', isAnyGrouped);
    toggleHideColumn('suite_label', isAnyGrouped);
    toggleHideColumn('suite_type', isAnyGrouped && groupBy.indexOf('suite_type') === -1);
    toggleHideColumn('suite_type_studio', !isAnyGrouped || groupBy.indexOf('suite_type') >= 0 || (suiteFilterValues.length && suiteFilterValues.indexOf('studio') === -1));
    toggleHideColumn('suite_type_bed1', !isAnyGrouped || groupBy.indexOf('suite_type') >= 0 || (suiteFilterValues.length && suiteFilterValues.indexOf('bed1') === -1));
    toggleHideColumn('suite_type_bed1_den', !isAnyGrouped || groupBy.indexOf('suite_type') >= 0 || (suiteFilterValues.length && suiteFilterValues.indexOf('bed1_den') === -1));
    toggleHideColumn('suite_type_bed2', !isAnyGrouped || groupBy.indexOf('suite_type') >= 0 || (suiteFilterValues.length && suiteFilterValues.indexOf('bed2') === -1));
    toggleHideColumn('suite_type_bed2_den', !isAnyGrouped || groupBy.indexOf('suite_type') >= 0 || (suiteFilterValues.length && suiteFilterValues.indexOf('bed2_den') === -1));
    toggleHideColumn('suite_type_bed3', !isAnyGrouped || groupBy.indexOf('suite_type') >= 0 || (suiteFilterValues.length && suiteFilterValues.indexOf('bed3') === -1));
    toggleHideColumn('suite_type_bed3_den', !isAnyGrouped || groupBy.indexOf('suite_type') >= 0 || (suiteFilterValues.length && suiteFilterValues.indexOf('bed3_den') === -1));
    toggleHideColumn('suite_type_penthouse', !isAnyGrouped || groupBy.indexOf('suite_type') >= 0 || (suiteFilterValues.length && suiteFilterValues.indexOf('penthouse') === -1));
    toggleHideColumn('status', isAnyGrouped && groupBy.indexOf('status') === -1);
    toggleHideColumn('status_pending', !isAnyGrouped || groupBy.indexOf('status') >= 0 || (statusFilterValues.length && statusFilterValues.indexOf('pending') === -1));
    toggleHideColumn('status_completed', !isAnyGrouped || groupBy.indexOf('status') >= 0 || (statusFilterValues.length && statusFilterValues.indexOf('completed') === -1));
    toggleHideColumn('status_rescinded', !isAnyGrouped || groupBy.indexOf('status') >= 0 || (statusFilterValues.length && statusFilterValues.indexOf('rescinded') === -1));
    toggleHideColumn('pp_sq_ft', isAnyGrouped && groupBy.indexOf('suite_type') === -1);
    toggleHideColumn('top_tier', !isAnyGrouped || groupBy.indexOf('agent_id') >= 0);
  }, [isAnyGrouped, groupBy, statusFilterValues, suiteFilterValues]);

  const topLevelExpanded = useMemo(() => {
    return preExpandedRows.every((row) => row.isExpanded);
  }, [preExpandedRows]);

  useEffect(() => {
    if (Object.keys(expanded).length > 0 && (!groupBy || groupBy.length !== 2)) {
      toggleAllRowsExpanded(false);
    }

    if (!topLevelExpanded && groupBy?.length && groupBy.length > 1) {
      preExpandedRows.forEach((row) => { toggleRowExpanded(row.id, true); });
    }
  }, [expanded, groupBy, topLevelExpanded]);

  useEffect(() => {
    skipResetRef.current = false;
  }, [data]);

  const reportOptions = useMemo(() => {
    return [
      { label: 'Firm Sales', value: 'completed' },
      { label: 'Pending Sales', value: 'pending' },
      { label: 'Rescinded Sales', value: 'rescinded' },
      { label: 'Status Breakdown', value: 'status' },
      { label: 'Sales per Agent', value: 'agent' },
      { label: 'Firm Sales per Agent', value: 'agent_completed' },
      { label: 'Project', value: 'project' },
      { label: 'Project by Agent', value: 'project_by_agent' },
      { label: 'Project by Status', value: 'project_by_status' },
      { label: 'Project by Suite Type', value: 'project_by_type' },
      { label: 'Developer', value: 'developer' },
    ];
  }, []);

  const agentOptions = useMemo(() => {
    return agents.map(agent => ({ label: agent.name, value: agent.id }));
  }, []);

  const projectOptions = useMemo(() => {
    return projects.map(project => ({ label: project.name, value: project.id }));
  }, []);

  const brokerOptions = useMemo(() => {
    return [{ label: '- No Brokerage -', value: null }, ...brokers.map(broker => ({ label: broker.name, value: broker.id }))];
  }, []);

  const teamOptions = useMemo(() => {
    return [{ label: '- No Team -', value: null }, ...teams.map(team => ({ label: team.name, value: team.id }))];
  }, []);

  const suiteOptions = useMemo(() => {
    return Object.keys(suiteTypes).map(type => ({ label: suiteNames[type], value: type }));
  }, []);

  const statusOptions = useMemo(() => {
    return [
      { label: 'Pending', value: 'pending' },
      { label: 'Firm', value: 'completed' },
      { label: 'Rescinded', value: 'rescinded' },
    ]
  }, []);

  const incentiveOptions = useMemo(() => {
    return [{ label: ' - No Incentive -', value: null }, ...incentives.map(incentive => ({ label: incentive.name, value: incentive.id }))];
  }, []);

  const [minSqFt, maxSqFt, minPrice, maxPrice] = useMemo(() => {
    let minSqFt = preFilteredRows.length ? preFilteredRows[0].values.sq_ft : 0;
    let maxSqFt = preFilteredRows.length ? preFilteredRows[0].values.sq_ft : 0;
    let minPrice = preFilteredRows.length ? preFilteredRows[0].values.price : 0;
    let maxPrice = preFilteredRows.length ? preFilteredRows[0].values.price : 0;
    preFilteredRows.forEach(row => {
      minSqFt = Math.min(row.values.sq_ft, minSqFt);
      maxSqFt = Math.max(row.values.sq_ft, maxSqFt);
      minPrice = Math.min(row.values.price, minPrice);
      maxPrice = Math.max(row.values.price, maxPrice);
    });
    return [minSqFt, maxSqFt, minPrice / 100, maxPrice / 100]
  }, [preFilteredRows]);

  const aggregations = useMemo(() => {
    if (isAnyGrouped) return null;

    const prices = preSortedRows.map(r => r.values.price);
    const averagePrice = average(prices);
    const powerboostPrices = preSortedRows.map(r => r.values.powerboost_price);
    const powerboostAveragePrice = average(powerboostPrices);

    return {
      id: `${preSortedRows.length} Deals`,
      project_id: `${uniqueCount(preSortedRows.map(r => r.values.project_id))} Projects`,
      agent_id: `${uniqueCount(preSortedRows.map(r => r.values.agent_id))} Agents`,
      broker_name: `${uniqueCount(preSortedRows.map(r => r.values.broker_id).filter(id => !!id))} Brokerages`,
      team_name: `${uniqueCount(preSortedRows.map(r => r.values.team_id).filter(id => !!id))} Teams`,
      sq_ft: `${Math.round(average(preSortedRows.map(r => r.values.sq_ft)) || 0)} avg`,
      price: (
        <>
          ${numberFormat.format(Math.round(sum(null, preSortedRows.map(r => r.values.price)) / 100))} total
          <br/>
          ${numberFormat.format(Math.round(isNaN(averagePrice) ? 0 : averagePrice / 100))} avg
        </>
      ),
      powerboost_price: (
        <>
          ${numberFormat.format(Math.round(sum(null, preSortedRows.map(r => r.values.powerboost_price)) / 100))} total
          <br/>
          ${numberFormat.format(Math.round(isNaN(powerboostAveragePrice) ? 0 : powerboostAveragePrice / 100))} avg
        </>
      ),
      incentive_name: `${uniqueCount(preSortedRows.map(r => r.values.incentive_id).filter(id => !!id))} Incentives`,
    };
  }, [preSortedRows, isAnyGrouped]);

  const renderPagination = () => {
    return (
      <div className="table-nav">
        <div><a className={canPreviousPage && 'enabled' || 'disabled'} onClick={() => gotoPage(0)}>First</a></div>
        <div><a className={canPreviousPage && 'enabled' || 'disabled'} onClick={() => previousPage()}>Previous</a></div>
        <div className="page-info">Page {pageIndex + 1} of {pageCount}</div>
        <div><a className={canNextPage && 'enabled' || 'disabled'} onClick={() => nextPage()}>Next</a></div>
        <div><a className={canNextPage && 'enabled' || 'disabled'} onClick={() => gotoPage(pageCount - 1)}>Last</a></div>
      </div>
    );
  };

  const renderSortIcon = (column) => {
    if (column.isSorted) {
      const icon = column.isSortedDesc ? 'keyboard_arrow_down' : 'keyboard_arrow_up';
      return <i className="material-icons">{icon}</i>;
    } else {
      return null;
    }
  };

  const clearAll = () => {
    setAgentFilter([]);
    setProjectFilter([]);
    setBrokerFilter([]);
    setSuiteTypeFilter([]);
    setStatusFilter([]);
    setIncentiveFilter([]);
    setSqFtFilter([]);
    setPriceFilter([]);

    setAllFilters([]);
    groupBy.length && groupBy.forEach(group => toggleGroupBy(group, false));
    toggleAllRowsExpanded(false);
  };

  const handleSetReport = (report) => {
    skipResetRef.current = true;
    setReport(report);
    clearAll();
    switch(report?.value) {
      case 'completed':
        setFilter('status', ['completed']);
        setStatusFilter([{ label: 'Firm', value: 'completed' }]);
        break;
      case 'pending':
        setFilter('status', ['pending']);
        setStatusFilter([{ label: 'Pending', value: 'pending' }]);
        break;
      case 'rescinded':
        setFilter('status', ['rescinded']);
        setStatusFilter([{ label: 'Rescinded', value: 'rescinded' }]);
        break;
      case 'status':
        toggleGroupBy('status', true);
        break;
      case 'agent':
        toggleGroupBy('agent_id', true);
        break;
      case 'agent_completed':
        toggleGroupBy('agent_id', true);
        setFilter('status', ['completed']);
        setStatusFilter([{ label: 'Firm', value: 'completed' }]);
        break;
      case 'project':
        toggleGroupBy('project_id', true);
        break;
      case 'project_by_agent':
        toggleGroupBy('project_id', true);
        toggleGroupBy('agent_id', true);
        break;
      case 'project_by_status':
        toggleGroupBy('project_id', true);
        toggleGroupBy('status', true);
        break;
      case 'project_by_type':
      case 'developer':
        toggleGroupBy('project_id', true);
        toggleGroupBy('suite_type', true);
        setFilter('status', ['completed']);
        setStatusFilter([{ label: 'Firm', value: 'completed' }]);
        break;
      default:
        break;
    }

    if (dateType.value !== 'ytd') {
      setDateType({ label: 'Year-to-date', value: 'ytd' });
    }
  };

  const handleDateRangeChange = (dateRange) => {
    fetchData({ dateRange });
  };

  const handleSetAgentFilter = (agents) => {
    setAgentFilter(agents);
    setFilter('agent_id', (agents || []).map(agent => agent.value));
  };

  const handleSetProjectFilter = (projects) => {
    setProjectFilter(projects);
    setFilter('project_id', (projects || []).map(project => project.value));
  };

  const handleSetBrokerFilter = (brokers) => {
    setBrokerFilter(brokers);
    setFilter('broker_id', (brokers || []).map(broker => broker.value));
  };

  const handleSetTeamFilter = (teams) => {
    setTeamFilter(teams);
    setFilter('team_id', (teams || []).map(team => team.value));
  };

  const handleSetSuiteTypeFilter = (suiteTypes) => {
    setSuiteTypeFilter(suiteTypes);
    setFilter('suite_type', (suiteTypes || []).map(type => type.value));
  };

  const handleSetStatusFilter = (statuses) => {
    setStatusFilter(statuses);
    setFilter('status', (statuses || []).map(status => status.value));
  };

  const handleSetIncentiveFilter = (incentives) => {
    setIncentiveFilter(incentives);
    setFilter('incentive_id', (incentives || []).map(incentive => incentive.value));
  };

  const handleSetSqFtFilter = (sqFt) => {
    const [min, max] = sqFt;
    const [newMin, newMax] = [min && parseInt(min, 10) || null, max && parseInt(max, 10) || null];
    setSqFtFilter([newMin, newMax]);
    setFilter('sq_ft', [newMin, newMax]);
  };

  const handleSetPriceFilter = (price) => {
    const [min, max] = price;
    const [newMin, newMax] = [min && parseInt(min.replace(/,/g, ''), 10) || null, max && parseInt(max.replace(/,/g, ''), 10) || null];
    setPriceFilter([newMin && numberFormat.format(newMin), newMax && numberFormat.format(newMax)]);
    setFilter('price', [newMin && newMin * 100, newMax && newMax * 100]);
  };

  const groupSizes = useMemo(() => {
    let start = 0;
    let status = 0;
    let suiteType = 0;
    let end = 0;
    headerGroups[0].headers.forEach((column) => {
      if (column.id.indexOf('status_') >= 0 || column.id.indexOf('price_') >= 0) {
        status++;
      } else if (column.id.indexOf('suite_type_') >= 0) {
        suiteType++;
      } else if (status > 0 || suiteType > 0) {
        end++;
      } else {
        start++;
      }
    });
    return { start, status, suiteType, end };
  }, [headerGroups[0].headers]);

  const renderGroupedHeader = () => {
    if (!groupBy.length || (!groupSizes.status && !groupSizes.suiteType)) return null;
    const statusStyle = { borderLeft: '1px solid black', borderRight: groupSizes.suiteType && '' || '1px solid black' };
    const suiteStyle = { borderLeft: '1px solid black', borderRight: '1px solid black' };
    return (
      <tr className="grouped-header">
        <th colSpan={groupSizes.start}/>
        {groupSizes.status && (
          <th style={statusStyle} colSpan={groupSizes.status}>
            <div className="header">
              {groupBy.length === 1 && (<div className="group-by-icon" onClick={() => toggleGroupBy('status')} title="Group by Status"><i className="material-icons">vertical_split</i></div>)}
              <div>Status</div>
            </div>
          </th>
        ) || null}
        {groupSizes.suiteType && (
          <th style={suiteStyle} colSpan={groupSizes.suiteType}>
            <div className="header">
              {groupBy.length === 1 && (<div className="group-by-icon" onClick={() => toggleGroupBy('suite_type')} title="Group by Suite Type"><i className="material-icons">vertical_split</i></div>)}
              <div>Suite Type</div>
            </div>
          </th>
        ) || null}
        <th colSpan={groupSizes.end}/>
      </tr>
    );
  };

  return (
    <>
      <DateRangeSelector onChange={handleDateRangeChange} controlledType={dateType} onChangeType={setDateType}>
        <div className="pure-u-1 pure-u-xl-1-4">
          <SelectField className="pure-u-md-23-24" label="Quick Reports" options={reportOptions} value={report} onChange={handleSetReport} isClearable={true}/>
        </div>
      </DateRangeSelector>
      <div className="pure-g">
        <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-2 pure-u-xl-1-4">
          <SelectField className="pure-u-md-23-24" label="Agent" options={agentOptions} value={agentFilter} onChange={handleSetAgentFilter} isClearable={true} isMulti={true}/>
        </div>
        <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-2 pure-u-xl-1-4">
          <SelectField className="pure-u-md-23-24" label="Project" options={projectOptions} value={projectFilter} onChange={handleSetProjectFilter} isClearable={true} isMulti={true}/>
        </div>
        <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-2 pure-u-xl-1-8">
          <SelectField className="pure-u-md-23-24" label="Brokerage" options={brokerOptions} value={brokerFilter} onChange={handleSetBrokerFilter} isClearable={true} isMulti={true}/>
        </div>
        <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-2 pure-u-xl-1-8">
          <SelectField className="pure-u-md-23-24" label="Team" options={teamOptions} value={teamFilter} onChange={handleSetTeamFilter} isClearable={true} isMulti={true}/>
        </div>
        <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-2 pure-u-xl-1-4">
          <SelectField className="pure-u-md-23-24" label="Suite Type" options={suiteOptions} value={suiteTypeFilter} onChange={handleSetSuiteTypeFilter} isClearable={true} isMulti={true}/>
        </div>
        <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-2 pure-u-xl-1-4">
          <SelectField className="pure-u-md-23-24" label="Status" options={statusOptions} value={statusFilter} onChange={handleSetStatusFilter} isClearable={true} isMulti={true}/>
        </div>
        <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-2 pure-u-xl-1-4">
          <SelectField className="pure-u-md-23-24" label="Incentive" options={incentiveOptions} value={incentiveFilter} onChange={handleSetIncentiveFilter} isClearable={true} isMulti={true}/>
        </div>
        <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-2 pure-u-xl-1-4">
          <div className="input pure-u-md-11-24">
            <label>Min Sq Ft</label>
            <input type="number" value={sqFtFilter[0] || ''} onChange={e => handleSetSqFtFilter([e.currentTarget.value, sqFtFilter[1]])} placeholder={minSqFt}/>
          </div>
          <div className="input pure-u-md-1-24"/>
          <div className="input pure-u-md-11-24">
            <label>Max Sq Ft</label>
            <input type="number" value={sqFtFilter[1] || ''} onChange={e => handleSetSqFtFilter([sqFtFilter[0], e.currentTarget.value])} placeholder={maxSqFt}/>
          </div>
        </div>
        <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-2 pure-u-xl-1-4">
          <div className="input pure-u-md-11-24">
            <label>Min Price</label>
            <input type="text" value={priceFilter[0] || ''} onChange={e => handleSetPriceFilter([e.currentTarget.value, priceFilter[1]])} placeholder={numberFormat.format(minPrice)}/>
          </div>
          <div className="input pure-u-md-1-24"/>
          <div className="input pure-u-md-11-24">
            <label>Max Price</label>
            <input type="text" value={priceFilter[1] || ''} onChange={e => handleSetPriceFilter([priceFilter[0], e.currentTarget.value])} placeholder={numberFormat.format(maxPrice)}/>
          </div>
        </div>
      </div>
      <br/>
      <table className="pure-table full-width" {...getTableProps()}>
        <thead>
        {renderGroupedHeader()}
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, i) => {
              const style = {
                borderLeft: ((groupSizes.status && i === groupSizes.start) || (groupSizes.suiteType && i === groupSizes.start + groupSizes.status)) && '1px solid black' || '',
                borderRight: ((groupSizes.status && !groupSizes.suiteType && i === groupSizes.start + groupSizes.status - 1) ||
                  (groupSizes.suiteType && i === groupSizes.start + groupSizes.status + groupSizes.suiteType - 1)) && '1px solid black' || '',
              };
              return (
                <th {...column.getHeaderProps(column.getSortByToggleProps({ style: { width: null, ...style } }))}>
                  <div className="header">
                    <div className="group-by-icon">
                      {column.canGroupBy && (column.isGrouped || !isAnyGrouped || groupBy.length < 2) ? (
                        <i className="material-icons" {...column.getGroupByToggleProps()}>
                          {column.isGrouped ? 'close' : 'vertical_split'}
                        </i>
                      ) : null}
                    </div>
                    <div>{column.render('Header')}</div>
                    <div className="sort-icon">
                      {renderSortIcon(column)}
                    </div>
                  </div>
                </th>
              );
            })}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <tr className={row.isExpanded && 'expanded' || ''} {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td {...cell.getCellProps()}>
                    {cell.isGrouped && false && groupBy.length === 2 ? (
                      <div className="expandable">
                        <div className="expand-icon">
                          <i className="material-icons" {...row.getToggleRowExpandedProps()}>
                            {row.isExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
                          </i>{' '}
                        </div>
                        <div>{cell.render('Cell')}</div>
                      </div>
                    ) : cell.isAggregated ? (
                      cell.render('Aggregated')
                    ) : cell.isPlaceholder ? null : (
                      cell.render('Cell')
                    )}
                  </td>
                );
              })}
            </tr>
          )
        })}
        {!rows.length && (
          <tr>
            <td colSpan={1000}>No Results</td>
          </tr>
        )}
        {aggregations && headerGroups.map(headerGroup => (
          <tr className="aggregations" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <td key={column.id}>{aggregations[column.id]}</td>
            ))}
          </tr>
        ))}
        </tbody>
      </table>
      {renderPagination()}
      <br/>
      <div className="pure-g">
        <div className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-2 pure-u-xl-1-4">
          <DownloadButton rows={rows} prepareRow={prepareRow} preGroupedRows={preGroupedRows}
            preExpandedRows={preExpandedRows} groupBy={groupBy}/>
        </div>
      </div>
    </>
  );
};


const DealsPage = ({
  agents,
  projects,
  brokers,
  teams,
  suite_names,
  suite_types,
  incentives,
  goal1,
  goal2,
}) => {
  const [ data, setData ] = useState([]);

  const fetchData = useCallback(({ dateRange }) => {
    axios.get('/deals.json', {
      params: {
        start_date: format(dateRange.startDate, 'yyyy-MM-dd'),
        end_date: format(dateRange.endDate, 'yyyy-MM-dd'),
      }
    }).then((response) => {
      setData(response.data.deals);
    })
  }, []);

  return (
    <>
      <DealsTable data={data} agents={agents} projects={projects} brokers={brokers} teams={teams} fetchData={fetchData}
        suiteNames={suite_names} suiteTypes={suite_types} incentives={incentives} goal1={goal1} goal2={goal2}/>
    </>
  );
};

export default hot(module)(DealsPage);
