import React, { useState, useCallback, useEffect } from 'react';
import useForm from 'react-hook-form';
import axios from 'axios';
import { hot } from 'react-hot-loader';
import Turbolinks from 'turbolinks';

import { FormTextField, FormSelectField, FormDatePicker } from "../shared/Inputs";

let Form = function({ deal, projects, agents, suite_types, suite_type_names, initial_incentives }) {
  const form = useForm({ defaultValues: deal });
  const { register, handleSubmit, errors, getValues, setValue, watch } = form;

  const [incentives, setIncentives] = useState(initial_incentives);
  const [isCreatingIncentive, setIsCreatingIncentive] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [serverError, setServerError] = useState(null);

  const projectId = watch('project_id');
  const selectedProject = projects.find(project => project.id === projectId);

  const onSubmit = data => {
    setSaving(true);
    setServerError(null);
    if (data.incentive_id === "-1") {
      data.incentive_id = null;
    }

    let result = deal.id ? axios.patch(`/deals/${deal.id}.json`, { deal: data }) : axios.post('/deals.json', { deal: data });
    result.then(response => {
      Turbolinks.visit(`/deals/${response.data.id}`);
    })
      .catch(e => {
        setServerError(e.response?.data?.error ?? 'There was a problem saving the deal, please try again later.');
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const createIncentive = (incentiveName) => {
    setIsCreatingIncentive(true);

    axios.post('/incentives', { name: incentiveName })
      .then(incentive => {
        setIncentives(incentives.concat([incentive.data]));
        setValue('incentive_id', incentive.data.id);
        setIsCreatingIncentive(false);
      })
      .catch(e => {
        setServerError(e.response?.data?.error ?? 'There was a problem creating the incentive, please try again later.');
        setIsCreatingIncentive(false);
      });
  };

  const projectOptions = projects.map(project => ({ label: project.name, value: project.id }));
  const agentOptions = agents.map(agent => ({ label: agent.name, value: agent.id }));
  const suiteOptions = Object.keys(suite_types).map(type => ({ label: suite_type_names[type], value: type }));

  const statusOptions = [
    { label: 'Pending', value: 'pending' },
    { label: 'Firm', value: 'completed' },
    { label: 'Rescinded', value: 'rescinded' },
  ];

  const incentiveOptions = incentives.map(incentive => ({ label: incentive.name, value: incentive.id }));

  return (
    <div className="page-content">
      {serverError && <div className='error_notification'>{serverError}</div>}
      <form id="deal-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-inputs pure-g">
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormSelectField label="Project" name="project_id" options={projectOptions} form={form} register={register({required: 'This field is required'})}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormSelectField label="Agent" name="agent_id" options={agentOptions} form={form} register={register({required: 'This field is required'})}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormDatePicker label="Date" name="date" form={form} register={register({required: 'This field is required'})}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormSelectField label="Suite Type" name="suite_type" options={suiteOptions} form={form} register={register({required: 'This field is required'})}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormTextField label="Square Feet" name="sq_ft" type="number" reg={register({required: 'This field is required'})} errors={errors}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormTextField label="Suite Label" name="suite_label" placeholder="Optional" reg={register} errors={errors}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormTextField label="Price" name="price" placeholder="350k" reg={register({required: 'This field is required'})} errors={errors}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormTextField label="Override Powerboost Multiplier" name="powerboost_multiplier" type="number" step="0.01" min="0" placeholder={`Leave blank to use project multiplier (${selectedProject?.powerboost_multiplier || '1.0'}x)`} reg={register} errors={errors}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormSelectField label="Status" name="status" options={statusOptions} form={form} register={register({required: 'This field is required'})}/>
          </div>
          <div className="pure-u-1 pure-u-lg-1-2">
            <FormSelectField label="Incentive" name="incentive_id" options={incentiveOptions} form={form}
              isCreatable={true} onCreateOption={createIncentive} isLoading={isCreatingIncentive} isClearable={true}/>
          </div>
        </div>
        <div className="pure-g">
          <div className="pure-u-1 pure-u-lg-1-2">
            <input type="submit" disabled={isSaving} value={isSaving ? "Saving..." : deal.id ? 'Save' : 'Create'}/>
          </div>
        </div>
      </form>
    </div>
  );
};

export default hot(module)(Form);
